import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CardContent,
  Grid,
  Typography,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import Dropzone from 'react-dropzone';
import { CloudUpload, ExpandMore, Info, SaveAlt } from '@mui/icons-material';
import Table from '../../../components/common/Table';
import { getOfficeList } from '../../../services/admin/users';
import { CSVLink } from 'react-csv';
import { createOrderFromCSV } from '../../../services/order/order';
import {
  guideTableData,
  guideTableDataPHH,
  sampleDataSetCSV,
  sampleDataSetCSVPhh,
} from '../../../utils/constants';
import { snakeToTitleCase } from '../../../utils/helpers';

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: '#ecf3f9a3',
  // flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    fontSize: '13px',
    fontWeight: '500',
  },
}));

const BulkUpload = () => {
  const navigate = useNavigate();
  const csvExport = useRef();
  const { order } = useSelector((state) => state?.authSlice);
  const bankAccountClient = order?.is_bank_account_client;
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [officeList, setOfficeList] = useState([]);
  const [skippedRows, setSkippedRows] = useState([]);
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState(null);
  const [url, setURL] = useState(null);

  useEffect(() => {
    if (file) {
      setURL(URL.createObjectURL(file));
    }
  }, [file]);

  useEffect(() => {
    fetchOfficeList();
  }, []);

  const tableData = useMemo(() => {
    let tableData = bankAccountClient ? guideTableDataPHH(officeList) : guideTableData(officeList);

    return tableData;
  }, [officeList, bankAccountClient]);

  const fetchOfficeList = async () => {
    setLoading(true);
    const res = await getOfficeList(order?.token, order?.client_id, order?.id);
    if (res?.success) {
      setOfficeList(res?.response?.map((ofc) => ofc.office_name) || []);
    }
    setLoading(false);
  };
  const downloadCSV = () => {
    let tempData = bankAccountClient
      ? sampleDataSetCSVPhh(officeList)
      : sampleDataSetCSV(officeList);
    if (!officeList?.length) {
      for (const record of tempData) {
        delete record.Office;
      }
    }
    setCsvData(tempData);
    setTimeout(() => {
      csvExport.current.link.click();
    }, 1000);
  };

  const validateAndCreateOrders = async (fileObj, skip = false) => {
    setLoading(true);
    setFile(fileObj);
    setErrors([]);
    const form = new FormData();
    form.append('order_file', fileObj);
    form.append('client_id', order?.client_id);
    form.append('skip_on_error', skip);
    if (skip) {
      form.append('skip_rows', skippedRows);
    }
    setSkippedRows([]);
    const res = await createOrderFromCSV(
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      order?.token
    );
    if (res?.success) {
      toast.success(res?.message);
      setTimeout(() => {
        setLoading(false);
        navigate(-1);
      }, 3000);
    } else {
      if (typeof res?.message === 'object') {
        setSkippedRows(res?.message?.skip_rows);
        let errorData = [];
        for (let key of Object.keys(res?.message || {})) {
          if (key === 'skip_rows') continue;
          errorData.push({ title: key, errors: res?.message?.[key] });
        }
        setErrors(errorData);
        toast.error('There are some errors in your CSV. Details will be displayed on the page.');
      } else {
        toast.error(res?.message);
      }

      setLoading(false);
    }
  };

  let columns = [
    {
      label: 'Column Name',
      id: 'column_name',
    },
    {
      label: 'Possible Values',
      id: 'possible_values',
    },
    {
      label: 'Example',
      id: 'example',
    },
    {
      label: 'Required',
      id: 'required',
    },
  ];

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12} sm={12} xs={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center title-bar'>
                <Typography variant='h4' className='mb-0'>
                  Bulk Order Upload
                </Typography>
              </Grid>

              <Grid item md={12} className='d-flex justify-center align-center mt-16'>
                {/* <Button
                  autoFocus
                  onClick={() => {
                    downloadCSV();
                  }}
                  variant='contained'
                  color='primary'
                  style={{ padding: '4px 12px' }}
                >
                  Download Sample CSV
                </Button> */}
                <div>
                  <CSVLink
                    data={csvData}
                    filename={'bulk_upload_sample.csv'}
                    ref={csvExport}
                    style={{ display: 'none' }}
                  ></CSVLink>
                  <Button
                    variant='contained'
                    onClick={downloadCSV}
                    className={'add-btn'}
                    hideAddIcon
                  >
                    <SaveAlt style={{ marginRight: '8px', fontSize: '18px' }} />
                    {'Download Sample CSV'}
                  </Button>
                </div>
              </Grid>
              <Grid item md={12} className='d-flex flex-column align-center mt-16'>
                <Dropzone
                  onDrop={(acceptedFiles) => validateAndCreateOrders(acceptedFiles[0], false)}
                  accept={{
                    'text/csv': ['.csv'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                      '.xls',
                      '.xlsx',
                    ],
                  }}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps, isDragActive, isFileDialogActive }) => (
                    <div
                      className={
                        isDragActive || isFileDialogActive ? 'drag-active dropzone' : 'dropzone'
                      }
                      style={{ padding: '20px 100px' }}
                    >
                      <div
                        className='dz-message d-flex flex-column justify-center'
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} name='file' />
                        <i className='h2 d-inline-block'>
                          <CloudUpload className='ms-2' fontSize={'medium'} />
                        </i>
                        <p className='mb-0 mt-0' style={{ fontWeight: '550', fontSize: '13px' }}>
                          Click or drag file to this area to upload
                        </p>
                        <p className='mb-0 mt-4 color-g f-12'>
                          Only single file is allowed to be uploaded and Only CSV or Excel will be
                          accepted.
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {file && (
                  <div
                    className='f-13-5 color-p mt-8 cursor-pointer'
                    onClick={() => {
                      if (url) {
                        window.open(url, '_blank');
                      }
                    }}
                  >
                    {file?.name}
                  </div>
                )}
              </Grid>
              {errors?.length ? (
                <Grid item md={12} className='mt-8'>
                  <Alert severity='warning' className='f-13 fw-500'>
                    There are some errors in your uploaded CSV. Please fix the issues mentioned
                    below and re-upload the CSV, or click the 'Skip and Create Orders' button below
                    to skip the erroneous records and create orders for the remaining valid records.
                    <div className='mt-16'>
                      {errors?.map((err, index) => {
                        return (
                          <Accordion defaultExpanded={index === 0}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls='panel1-content'
                              id='panel1-header'
                            >
                              <div className='fw-550 f-13' style={{ textTransform: 'capitalize' }}>
                                {err?.title}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              {Object.keys(err.errors || {}).map((errKey, idx) => {
                                return (
                                  <Grid container>
                                    <Grid
                                      item
                                      md={3}
                                      className='f-13 fw-500 p-8 d-flex align-items-center color-p'
                                      border={'1px solid rgba(50, 50, 93, 0.25)'}
                                      borderRight={'0px'}
                                      borderTop={
                                        idx === 0 ? '1px solid rgba(50, 50, 93, 0.25)' : '0px'
                                      }
                                    >
                                      {snakeToTitleCase(errKey)}
                                    </Grid>
                                    <Grid
                                      item
                                      md={9}
                                      sm={9}
                                      xs={9}
                                      className='fw-450 d-flex justify-start f-13 p-8'
                                      border={'1px solid rgba(50, 50, 93, 0.25)'}
                                      borderTop={
                                        idx === 0 ? '1px solid rgba(50, 50, 93, 0.25)' : '0px'
                                      }
                                    >
                                      {err?.errors?.[errKey]?.map((errMessage) => (
                                        <div>{errMessage}</div>
                                      ))}
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </div>
                    <div className='d-flex justify-end align-center mt-8'>
                      <Button
                        onClick={() => {
                          validateAndCreateOrders(file, true);
                        }}
                      >
                        Skip and Create Orders
                      </Button>
                    </div>
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              <Grid item md={12} className='d-flex justify-start align-center mt-8'>
                {' '}
                <div className='permission-info' style={{ marginTop: '16px' }}>
                  <div className='title color-p'>
                    <Info style={{ fontSize: '18px' }} /> <p className='title-text'>Notes:</p>
                  </div>
                  <div className='mt-8 f-13-5 fw-500 color-g'>
                    Refer to the table below for details on the column names and associated values.
                  </div>
                </div>
              </Grid>
              <Grid item md={12} className='mt-16'>
                <Table loader={loading} columns={columns} data={tableData} hidePagination={true} />
              </Grid>
            </CardContent>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BulkUpload;
