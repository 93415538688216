import axios from 'axios';
import _get from 'lodash/get';
const URL =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? process.env.REACT_APP_BACKEND_URL
    : window.location.origin + '/api/';

export default function api() {
  const axiosInstance = axios.create({
    baseURL: URL,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': '69420',
    },
    withCredentials: true,
  });

  const ResponseBody = (response) => {
    return { ...response.data };
  };

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const statusCode = _get(error, 'response.status', '');
      if (statusCode === 401) {
        // store.dispatch(logout());
      }
      return error.response || {};
    }
  );

  return {
    get: (url, data) => axiosInstance.get(url, data).then(ResponseBody),
    post: (url, data, config) => axiosInstance.post(url, data, config).then(ResponseBody),
    put: (url, data, config) => axiosInstance.put(url, data, config).then(ResponseBody),
    delete: (url, config) => axiosInstance.delete(url, config).then(ResponseBody),
  };
}

export function wihoutPrefix() {
  const axiosInstance = axios.create({
    baseURL: URL,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Cache-Control': 'no cache',
    },
    withCredentials: true,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      // Capture the cookies from the response
      const cookies = response.headers['Set-Cookie'];
      if (cookies) {
        axiosInstance.defaults.headers['Cookie'] = cookies.join(';');
      }
      return response;
    },
    (error) => {
      const statusCode = error.response ? error.response.status : '';
      if (statusCode === 401) {
        // Handle unauthorized error (e.g., log out the user)
      }
      return Promise.reject(error);
    }
  );

  return {
    get: (url, data) => axiosInstance.get(url, data),
    post: (url, data, config) => axiosInstance.post(url, data, config),
    put: (url, data, config) => axiosInstance.put(url, data, config),
    delete: (url, config) => axiosInstance.delete(url, config),
  };
}

export function authorizedApi(token) {
  const axiosInstance = axios.create({
    baseURL: URL,
    headers: {
      Authorization: 'Bearer ' + token,
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': '69420',
    },
    withCredentials: true,
  });

  const ResponseBody = (response) => {
    return { ...response.data };
  };

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const statusCode = _get(error, 'response.status', '');
      if (statusCode === 401) {
        // store.dispatch(logout());
      }
      return error.response || {};
    }
  );

  return {
    get: (url, data) => axiosInstance.get(url, data).then(ResponseBody),
    post: (url, data, config) => axiosInstance.post(url, data, config).then(ResponseBody),
    put: (url, data, config) => axiosInstance.put(url, data, config).then(ResponseBody),
    delete: (url, config) => axiosInstance.delete(url, config).then(ResponseBody),
  };
}
