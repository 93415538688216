import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  CardContent,
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Popover,
} from '@mui/material';
import { toast } from 'react-toastify';
import Table from '../../../components/common/Table';
import { getList, getListPHH } from '../../../services/admin/reports';
import Loader from '../../../components/common/Loader';
import { SaveAlt, Settings, ViewTimeline } from '@mui/icons-material';
import ActionMenu from '../../../components/common/ActionMenu';
import TimelineDrawer from '../../../components/admin/reports/TimelineDrawer';
import { CSVLink } from 'react-csv';
import { getClients } from '../../../services/admin/users';
import DateRangeFilter from './DateRangeFilter';
import moment from 'moment';
import { ADMIN_ROLE, CLIENT_ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';
import { getConfigurations, saveConfig } from '../../../services/admin/configurations';
import ConfigConfirmationModal from '../../../components/order/ConfigConfirmationModal';
import { login } from '../../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';

const dateFields = [
  'order_date_from',
  'order_date_to',
  'form_completed_date_to',
  'form_completed_date_from',
  'verification_completed_date_from',
  'verification_completed_date_to',
  'form_completed_date_from',
];
// const test = {
//   order_id: 228,
//   company_name: 'Office Questions',
//   address: ' 18721 Paseo Picasso, Irvine, Ca, 92603',
//   created_date: '07/05/2024',
//   order_completed_date: '',
//   question_form: [
//     {
//       user_participant_type: 'Seller',
//       data_verified_at: '',
//       participants: [
//         {
//           participant_name: 'John Doe',
//           participant_number: 1,
//           form_completed_date: '',
//         },
//       ],
//     },
//     {
//       user_participant_type: 'Buyer',
//       data_verified_at: '',
//       participants: [
//         {
//           participant_name: 'Buyer Name',
//           participant_number: 1,
//           form_completed_date: '',
//         },
//       ],
//     },
//   ],
// };
const Reports = () => {
  const csvExport = useRef();
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const bankAccountClient = admin?.is_bank_account_client || order?.is_bank_account_client;
  const isSuperAdmin = admin?.user_role_name === SUPER_ADMIN_ROLE ? true : false;
  const isAdmin =
    order?.user_role_name === ADMIN_ROLE || order?.user_role_name === SUPER_ADMIN_ROLE
      ? true
      : false;
  const token = admin?.token || order?.token;
  const clientId = admin?.client_id || order?.client_id;
  const isClientAdmin = order?.user_role_name === CLIENT_ADMIN_ROLE;
  const defaultColumns = useMemo(() => {
    let cols = bankAccountClient
      ? [
          { id: 'address', show: true, label: 'Address' },
          { id: 'participant_name', show: true, label: 'Borrower' },
          { id: 'bank_account_number', show: true, label: 'Account Number' },
          { id: 'bank_routing_number', show: true, label: 'Bank ABA' },
          { id: 'bank_name_on_account', show: true, label: 'Name On Account' },
          { id: 'bank_name', show: true, label: 'Bank Name' },
          { id: 'bank_city', show: true, label: 'Bank City' },
          { id: 'bank_state', show: true, label: 'Bank State' },
          { id: 'current_vesting', show: true, label: 'Current Vesting' },
          { id: 'bank_status', show: true, label: 'Bank Account Status' },
          { id: 'created_date', show: true, label: 'Order Creation Date' },
        ]
      : [
          { id: 'actions', show: true, label: 'Actions' },
          { id: 'escrow_num', show: true, label: 'Escrow No.' },
          { id: 'company_name', show: true, label: 'Company' },
          { id: 'address', show: true, label: 'Address' },
          { id: 'created_date', show: true, label: 'Order Creation Date' },
          {
            id: 'order_completed_date',
            label: 'Order Completion Date',
            show: true,
          },
          { id: 'order_completed_days', show: true, label: 'Processing Time (Days)' },
        ];
    if (!isSuperAdmin) cols = cols?.filter((column) => column.id !== 'company_name');

    return cols;
  }, [bankAccountClient]);
  const [configConfirmationOpen, setConfigConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [visible, setVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [configurations, setConfigurations] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [showHideColumns, setShowHideColumns] = useState(cloneDeep(defaultColumns));

  useEffect(() => {
    fetchClientList();
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    setLoading(true);
    const res = await getConfigurations(order?.client_id, order?.token);
    if (res?.success) {
      setConfigurations(res?.response);
      order?.exportShowHideColumns
        ? setShowHideColumns(cloneDeep(order?.exportShowHideColumns))
        : setShowHideColumns(
            res?.response?.export_column_config && res?.response?.export_column_config?.length
              ? res?.response?.export_column_config
              : cloneDeep(defaultColumns)
          );
    } else {
      setShowHideColumns(
        order?.exportShowHideColumns ? order?.exportShowHideColumns : cloneDeep(defaultColumns)
      );
    }
    setLoading(false);
  };

  const fetchClientList = async () => {
    const res = await getClients(token);
    if (res?.success) {
      setClientList(
        res?.response.map((client) => {
          return { label: client.full_name, value: client.id };
        }) || []
      );
    }
  };
  const tableData = useMemo(() => {
    if (list?.length) {
      return list?.map((record) => {
        return {
          ...record,
          actions: () => (
            <div>
              <ActionMenu
                actions={[
                  {
                    label: `View Participant's Progress`,
                    logo: <ViewTimeline className='action-menu-item' />,
                    onClick: () => {
                      setVisible(true);
                      setCurrentRecord(record);
                    },
                  },
                ]}
              />
            </div>
          ),
          bank_status: () => (
            <div
              className={`${
                record?.bank_account_status === 'verified' ? 'color-s' : 'color-w'
              } text-capitalize`}
            >
              {record?.bank_account_status}
            </div>
          ),
        };
      });
    }
    return [];
  }, [list]);

  const columns = useMemo(() => {
    let tempCols = bankAccountClient
      ? [
          {
            id: 'address',
            label: 'Address',
            render: () => <div style={{ width: '150px' }}>Address</div>,
          },
          {
            id: 'participant_name',
            label: 'Borrower',
            render: () => <div style={{ width: '100px' }}>Borrower</div>,
          },
          {
            id: 'bank_account_number',
            label: 'Account Number',
            render: () => <div style={{ width: '100px' }}>Account Number</div>,
          },
          {
            id: 'bank_routing_number',
            label: 'Bank ABA',
            render: () => <div style={{ width: '90px' }}>Bank ABA</div>,
          },
          {
            id: 'bank_name_on_account',
            label: 'Name On Account',
            render: () => <div style={{ width: '150px' }}>Name On Account</div>,
          },
          {
            id: 'bank_name',
            label: 'Bank Name',
            render: () => <div style={{ width: '120px' }}>Bank Name</div>,
          },
          {
            id: 'bank_city',
            label: 'Bank City',
            render: () => <div style={{ width: '80px' }}>Bank City</div>,
          },
          {
            id: 'bank_state',
            label: 'Bank State',
            render: () => <div style={{ width: '80px' }}>Bank State</div>,
          },
          {
            id: 'current_vesting',
            label: 'Current Vesting',
            render: () => <div style={{ width: '100px' }}>Current Vesting</div>,
          },
          {
            id: 'bank_status',
            label: 'Bank Account Status',
            type: 'jsx',
            render: () => <div style={{ width: '100px' }}>Bank Account Status</div>,
          },
          {
            id: 'created_date',
            label: 'Order Creation Date',
            render: () => <div style={{ width: '100px' }}>Order Creation Date</div>,
          },
        ]
      : [
          { id: 'actions', label: 'Actions', type: 'jsx' },

          {
            id: 'escrow_num',
            label: 'Escrow No.',
          },
          {
            id: 'company_name',
            label: 'Company',
          },
          {
            id: 'address',
            label: 'Address',
            width: '400px',
          },
          {
            id: 'created_date',
            label: 'Order Creation Date',
          },
          {
            id: 'order_completed_date',
            label: 'Order Completion Date',
          },
          {
            id: 'order_completed_days',
            label: 'Processing Time (Days)',
          },
        ];

    if (!isSuperAdmin) tempCols = tempCols?.filter((column) => column.id !== 'company_name');
    const showHideCols = {};
    showHideColumns?.forEach((col) => {
      showHideCols[col?.id] = col?.show;
    });
    tempCols = tempCols.filter((col) => showHideCols[col?.id]);

    return tempCols;
  }, [showHideColumns]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Object.keys(filters || {})?.length) {
        fetchList();
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [filters]);

  useEffect(() => {
    fetchList();
  }, [currPage, perPageRecords]);

  const fetchList = async () => {
    setLoading(true);
    let str = `page=${currPage + 1}&per_page=${perPageRecords}&is_listing=yes`;
    Object.keys(filters).forEach((key) => {
      if (filters?.[key]) {
        if (dateFields?.includes(key)) {
          str = str + `&${key}=${moment(filters?.[key]).format('YYYY-MM-DD')}`;
        } else {
          str = str + `&${key}=${encodeURIComponent(filters?.[key])}`;
        }
      }
    });
    const res = bankAccountClient
      ? await getListPHH(token, str, clientId)
      : await getList(token, str);
    if (res?.success) {
      setList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setList([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const getCSVData = async () => {
    setLoading(true);
    let str = `page=${currPage + 1}&per_page=${perPageRecords}&is_listing=no`;
    Object.keys(filters).forEach((key) => {
      if (filters?.[key]) {
        if (dateFields?.includes(key)) {
          str = str + `&${key}=${moment(filters?.[key]).format('YYYY-MM-DD')}`;
        } else {
          str = str + `&${key}=${encodeURIComponent(filters?.[key])}`;
        }
      }
    });
    const res = bankAccountClient
      ? await getListPHH(token, str, clientId)
      : await getList(token, str);
    if (res?.success) {
      let tempData = res?.response?.data;
      let generatedData = [];
      const showHideCols = {};
      showHideColumns?.forEach((col) => {
        showHideCols[col?.label] = col?.show;
      });
      if (!bankAccountClient) {
        tempData.forEach((record) => {
          let tempObj = {
            'Escrow No.': `\u200B${record?.escrow_num}` ?? '',
            Company: record?.company_name ?? '',
            Address: record?.address ?? '',
            'Order Creation Date': record?.created_date ?? '',
            'Order Completion Date': record?.order_completed_date ?? '',
            'Processing Time (Days)': record?.order_completed_days ?? '',
          };
          if (!isSuperAdmin) delete tempObj.Company;
          for (const key in tempObj) {
            if (!showHideCols[key]) delete tempObj[key];
          }

          generatedData.push(tempObj);
        });
      } else {
        tempData.forEach((record) => {
          let tempObj = {
            Address: record?.address ?? '',
            Borrower: record?.participant_name ?? '',
            'Account Number': record?.bank_account_number ?? '',
            'Bank ABA': record?.bank_routing_number ?? '',
            'Name On Account': record?.bank_name_on_account ?? '',
            'Bank Name': record?.bank_name ?? '',
            'Bank City': record?.bank_city ?? '',
            'Bank State': record?.bank_state ?? '',
            'Current Vesting': record?.current_vesting ?? '',
            'Bank Account Status': record?.bank_account_status ?? '',
            'Order Creation Date': record?.created_date ?? '',
          };
          for (const key in tempObj) {
            if (!showHideCols[key]) delete tempObj[key];
          }
          generatedData.push(tempObj);
        });
      }
      setCsvData(generatedData);
      setTimeout(() => {
        csvExport.current.link.click();
      }, 1000);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };

  const onRowClick = (row) => {
    setCurrentRecord(row);
    setVisible(true);
  };

  const setDate = (dateName, value) => {
    setFilters((prev) => {
      return { ...prev, [dateName]: value };
    });
  };

  const getCSVName = () => {
    if (filters?.client_name) {
      return `order_report_${filters?.client_name?.toLowerCase()?.replace(/ /g, '_')}.csv`;
    }
    return `order_report_all.csv`;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    order?.exportShowHideColumns
      ? setShowHideColumns(cloneDeep(order?.exportShowHideColumns))
      : setShowHideColumns(
          configurations?.export_column_config && configurations?.export_column_config?.length
            ? configurations?.export_column_config
            : cloneDeep(defaultColumns)
        );
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = configConfirmationOpen ? 'simple-popover' : undefined;

  const saveUserSettings = async () => {
    setAnchorEl(null);
    dispatch(
      login({
        data: { ...order, exportShowHideColumns: cloneDeep(showHideColumns) },
        code: 'order',
      })
    );
    if (isClientAdmin) {
      setConfigConfirmationOpen(true);
    }
  };

  const saveGlobalConfig = async () => {
    setLoading(true);
    const res = await saveConfig(
      {
        client_id: order?.client_id,
        export_column_array: showHideColumns,
        listing_column_array: configurations?.listing_column_config || [],
      },
      order?.token
    );
    if (res?.success) {
      toast?.success(res?.message);
      setConfigConfirmationOpen(false);
    } else {
      toast?.error(res?.message);
    }
    setLoading(false);
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <ConfigConfirmationModal
        open={configConfirmationOpen}
        setOpen={setConfigConfirmationOpen}
        handleSave={saveGlobalConfig}
      />
      <TimelineDrawer visible={visible} setVisible={setVisible} record={currentRecord} />
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent style={{ padding: '0px 16px' }}>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Grid container className='report-filter'>
                  <Grid container></Grid>
                  <Grid container>
                    {' '}
                    <Grid item sm={12} md={8.5}>
                      <Grid container spacing={1} className='reporting-filters-main'>
                        {isSuperAdmin ? (
                          <Grid item className='filter-select'>
                            <div className='f-13 fw-550 mb-4'>Company Name</div>
                            <Select
                              sx={{
                                width: '12vw',
                                // borderRadius: '0px',
                              }}
                              defaultValue={''}
                              displayEmpty={true}
                              value={filters?.company_id}
                              onChange={(e) => {
                                setFilters((old) => {
                                  return { ...old, client_name: e.target.value };
                                });
                              }}
                              placeholder='Select Company'
                            >
                              <MenuItem value=''>
                                <div>All</div>
                              </MenuItem>
                              {clientList?.map((office) => (
                                <MenuItem value={office.label} key={office.label}>
                                  {office.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid item>
                          <div className='f-13 mb-4 fw-550'>Address</div>
                          <TextField
                            sx={{
                              // Reset common styles
                              width: '12vw',
                              // borderRadius: '0px',
                              // Add any additional styles you need
                            }}
                            defaultValue={''}
                            displayEmpty={true}
                            value={filters?.address}
                            onChange={(e) => {
                              setFilters((old) => {
                                return { ...old, address: e.target.value };
                              });
                            }}
                          />{' '}
                        </Grid>
                        <Grid item>
                          <div className='f-13 mb-4 fw-550'>Order Creation Date</div>
                          <DateRangeFilter
                            setDate={setDate}
                            startDate={filters?.order_date_from}
                            endDate={filters?.order_date_to}
                            startDateName={'order_date_from'}
                            endDateName={'order_date_to'}
                          />
                        </Grid>{' '}
                        {!bankAccountClient ? (
                          <Grid item>
                            <div className='f-13 mb-4 fw-550'>Participant Form Completed Date</div>
                            <DateRangeFilter
                              setDate={setDate}
                              startDate={filters?.form_completed_date_from}
                              endDate={filters?.form_completed_date_to}
                              startDateName={'form_completed_date_from'}
                              endDateName={'form_completed_date_to'}
                            />
                          </Grid>
                        ) : (
                          ''
                        )}
                        {!bankAccountClient ? (
                          <Grid item>
                            <div className='f-13 mb-4 fw-550'>Verification Completed Date</div>
                            <DateRangeFilter
                              setDate={setDate}
                              startDate={filters?.verification_completed_date_from}
                              endDate={filters?.verification_completed_date_to}
                              startDateName={'verification_completed_date_from'}
                              endDateName={'verification_completed_date_to'}
                              title={'Verification Completion Date'}
                            />
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                    <Grid item sm={12} md={3.5} className='d-flex align-items-center justify-end'>
                      <div>
                        <CSVLink
                          data={csvData}
                          filename={getCSVName()}
                          ref={csvExport}
                          style={{ display: 'none' }}
                        ></CSVLink>
                        <Button
                          variant='contained'
                          onClick={getCSVData}
                          className={'add-btn'}
                          style={{ padding: '4px 12px' }}
                          hideAddIcon
                        >
                          <SaveAlt style={{ marginRight: '8px', fontSize: '18px' }} />
                          {'Export'}
                        </Button>
                      </div>

                      <>
                        <Button
                          aria-describedby={id}
                          autoFocus
                          onClick={handleClick}
                          variant='contained'
                          color='primary'
                          style={{ padding: '4px 12px' }}
                          className='ml-8'
                        >
                          <Settings fontSize='small' className='mr-4' />
                          Manage Columns
                        </Button>
                        <Popover
                          id={id}
                          open={popoverOpen}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <div className='flex-column p-8'>
                            {showHideColumns?.map((col, index) => {
                              return (
                                <div className='flex-row p-0 show-checkbox'>
                                  <Checkbox
                                    checked={col?.show}
                                    onChange={(e) => {
                                      setShowHideColumns((prevCols) => {
                                        let existingCols = [...prevCols];
                                        existingCols[index].show = e.target.checked;
                                        return existingCols;
                                      });
                                    }}
                                    color='primary'
                                    size='small'
                                  />
                                  <label style={{ minWidth: 'unset' }} className='fw-400 f-12 mt-4'>
                                    {col?.label}
                                  </label>
                                </div>
                              );
                            })}
                            <div className='flex-row mt-8 justify-end'>
                              <Button className='popover-btn' onClick={handleClose}>
                                Cancel
                              </Button>
                              <Button
                                className='popover-btn'
                                variant='contained'
                                onClick={saveUserSettings}
                                disabled={!showHideColumns?.filter((col) => col?.show)?.length}
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </Popover>
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  onRowClick={onRowClick}
                  isClickable={true}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Reports;
