import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Drawer,
  Divider,
  styled,
  Tooltip,
  Grid,
  TextField,
  Alert,
  InputAdornment,
  Button,
  Tab,
  Tabs,
} from '@mui/material';
import {
  Close,
  GppMaybeOutlined,
  Help,
  HelpOutline,
  History,
  TaskAlt,
  VerifiedUserOutlined,
} from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import moment from 'moment/moment';
import { getSourceList } from '../../../services/admin/sources';
import { SSN_FIELDS } from '../../../utils/constants';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const SourceDetailsDrawer = ({ open, setOpen, curData, onVerify, onUnverify, disabled }) => {
  const { pathname } = useLocation();
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const user =
    pathname.split('/')[1] === 'admin'
      ? admin?.first_name + ' ' + admin?.last_name
      : order?.client_name && !order?.parent_id
      ? order?.client_name
      : order?.first_name + ' ' + order?.last_name;
  const [elementData, setElementData] = useState(cloneDeep(curData));
  const [notes, setNotes] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [sourceList, setSourceList] = useState({});
  const historyDetails = cloneDeep(elementData?.history);

  useEffect(() => {
    setElementData(
      cloneDeep({ ...curData, user_value: curData.user_value ? curData.user_value : '' })
    );
    setNotes('');
  }, [curData]);

  useEffect(() => {
    fetchSourceList();
  }, []);

  const handleChange = (e) => {
    setElementData((data) => {
      return { ...data, user_value: e.target.value };
    });
  };

  const fetchSourceList = async () => {
    const res = await getSourceList(admin?.token);
    if (res.success) {
      setSourceList(res?.response);
    }
  };

  return (
    <Drawer
      sx={{
        width: window.innerWidth > 800 ? 700 : window.innerWidth - 50,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: window.innerWidth > 800 ? 700 : window.innerWidth - 20,
          boxSizing: 'border-box',
        },
      }}
      anchor='right'
      open={open}
      onClose={() => {
        setTabValue(0);
        setOpen(false);
      }}
    >
      <DrawerHeader style={{ minHeight: '55px' }}>
        <div className='f-14 fw-500'>Source Verification Details </div>
        {elementData?.is_verified_with_source || elementData?.verified_with_source_by_user_id ? (
          <Tooltip title='This field is verified.'>
            <VerifiedUserOutlined fontSize='small' className='color-s cursor-pointer ml-4' />
          </Tooltip>
        ) : (
          <Tooltip title='This field is not verified.'>
            <GppMaybeOutlined fontSize='small' className='color-w cursor-pointer ml-4' />
          </Tooltip>
        )}
        <Close
          sx={{ position: 'absolute', right: '10px', top: '14px', color: 'grey' }}
          fontSize='small'
          onClick={() => {
            setTabValue(0);
            setOpen(false);
          }}
          className='cursor-pointer'
        />
      </DrawerHeader>
      <Divider />
      <Tabs
        value={tabValue}
        onChange={(e, newValue) => {
          setTabValue(newValue);
        }}
        className='order-history'
        style={{ margin: '0 auto' }}
      >
        <Tab
          label='VERIFICATION DETAILS'
          icon={<TaskAlt fontSize='small' />}
          iconPosition='start'
        />
        <Tab label='Notes & History' icon={<History fontSize='small' />} iconPosition='start' />
      </Tabs>
      {tabValue === 0 ? (
        <>
          <div style={{ overflowY: 'scroll', height: '580px' }}>
            {(elementData?.verified_with_source_by_user_id ||
              elementData?.is_verified_with_source) && (
              <Alert
                severity='success'
                // variant='outlined'
                className='mt-24'
                style={{
                  fontSize: '13.5px',
                  padding: '0px 10px',
                }}
              >
                This field is verified
                {elementData?.is_verified_with_source
                  ? ' by ' +
                    (sourceList[elementData?.verifiedSource]
                      ? sourceList[elementData?.verifiedSource]
                      : elementData?.verifiedSource) +
                    ' source'
                  : ' manually'}
              </Alert>
            )}
            {elementData?.rejected_by && (
              <Alert
                severity='warning'
                // variant='outlined'
                className='mt-24'
                style={{
                  fontSize: '13.5px',
                  padding: '0px 10px',
                }}
              >
                This field is rejected by Admin.
              </Alert>
            )}
            <div className='p-16 source-details-modal'>
              <div className='f-13-5 mt-5 fw-600'>Note : </div>
              <div className='f-13 mt-8 mb-16 color-g' style={{ fontStyle: 'italic' }}>
                {elementData?.sources?.length
                  ? `This is the list of all possible values for this field. All data is fetched from
              various sources. You can verify or reject fields by monitoring the output of various
              sources listed below.`
                  : `No source data found for this field. You need to verify or reject this field manually.`}
              </div>
              {elementData?.sources?.length ? (
                <Divider textAlign='left' className='f-13 color-g'>
                  Source data
                </Divider>
              ) : (
                ''
              )}
              <Grid
                container
                className='client-user-form form-field-container'
                columnSpacing={1}
                rowSpacing={1}
              >
                {elementData?.sources?.length
                  ? elementData?.sources?.map((data, index) => {
                      return (
                        <Grid
                          container
                          className={index !== 0 ? 'mt-8' : 'mt-16'}
                          style={{ marginLeft: '8px' }}
                        >
                          <Grid
                            item
                            md={3}
                            sm={3}
                            xs={3}
                            className='f-13 fw-500 p-8 d-flex align-items-center'
                            border={'1px solid rgba(50, 50, 93, 0.25)'}
                            borderRight={'0px'}
                          >
                            {sourceList[data?.source] ? sourceList[data?.source] : data?.source}{' '}
                            {sourceList?.[data?.source] &&
                            sourceList?.[data?.source] === 'Provider TX' ? (
                              <Tooltip
                                title={
                                  'This is the value from the field where the order was initially created.'
                                }
                              >
                                <Help
                                  style={{ fontSize: '16px', marginLeft: '2px' }}
                                  className='cursor-pointer'
                                />
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </Grid>
                          <Grid
                            item
                            md={9}
                            sm={9}
                            xs={9}
                            className='d-flex justify-end f-13 p-8'
                            border={'1px solid rgba(50, 50, 93, 0.25)'}
                          >
                            {data?.value}
                          </Grid>
                        </Grid>
                      );
                    })
                  : ''}
              </Grid>

              <Divider textAlign='left' className='f-13 color-g mt-16'>
                User's value.
              </Divider>
              <Grid item md={12} className='text-field-container'>
                <TextField
                  id='outlined-basic'
                  className='mt-16'
                  variant='outlined'
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  disabled={
                    elementData?.is_verified_with_source ||
                    elementData?.verified_with_source_by_user_id ||
                    SSN_FIELDS?.includes(elementData.element_name)
                  }
                  value={
                    SSN_FIELDS?.includes(elementData.element_name)
                      ? elementData?.ssn_ein_masked_number
                      : elementData?.user_value
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Tooltip
                          title={
                            elementData?.is_verified_with_source ||
                            elementData?.verified_with_source_by_user_id
                              ? 'Field is already verified.'
                              : 'You can modify the field value before verifying it.'
                          }
                        >
                          <HelpOutline
                            style={{ color: 'grey', cursor: 'pointer' }}
                            fontSize='small'
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Divider textAlign='left' className='f-13 color-g mt-16'>
                Add Note
              </Divider>
              <Grid item md={12} className='text-field-container'>
                <textarea
                  id='outlined-basic'
                  className='mt-16'
                  variant='outlined'
                  style={{ width: '100%', border: '1px solid #b8b7b7', borderRadius: '3px' }}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  value={notes}
                />
              </Grid>
            </div>
          </div>
          <Divider className='mt-20' />
          <Grid item className='p-8 d-flex justify-end mr-8'>
            <Button
              autoFocus
              onClick={() => {
                setTabValue(0);
                setOpen(false);
              }}
              className='p-2'
              color='secondary'
              style={{ color: 'grey', marginRight: '8px' }}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              color='primary'
              disabled={disabled}
              variant='contained'
              style={{ padding: '4px 8px' }}
              onClick={() => {
                let elem = { ...elementData };
                elem.history = elem?.history?.length
                  ? [
                      ...elem.history,
                      {
                        note: notes,
                        created_by: user,
                        approval:
                          elem?.is_verified_with_source || elem?.verified_with_source_by_user_id
                            ? 0
                            : 1,
                        created_at: moment(new Date()).format('YYYY-MM-DD'),
                      },
                    ]
                  : [
                      {
                        note: notes,
                        created_by: user,
                        approval:
                          elem?.is_verified_with_source || elem?.verified_with_source_by_user_id
                            ? 0
                            : 1,
                        created_at: moment(new Date()).format('YYYY-MM-DD'),
                      },
                    ];
                elem?.is_verified_with_source || elem?.verified_with_source_by_user_id
                  ? onUnverify([elem])
                  : onVerify([elem]);
                setNotes('');
              }}
            >
              {elementData?.is_verified_with_source || elementData?.verified_with_source_by_user_id
                ? 'Reject'
                : 'Verify'}
            </Button>
          </Grid>
        </>
      ) : (
        ''
      )}
      {tabValue === 1 ? (
        <div className='p-8 mt-8'>
          <Grid container className='note-container' style={{ boxShadow: 'none' }}>
            <Grid item md={3} sm={3} xs={3} className='f-14 fw-500 p-4'>
              User
            </Grid>
            <Grid
              item
              md={2}
              sm={2}
              xs={2}
              className='f-14 fw-500 p-4'
              style={{ wordWrap: 'break-word' }}
            >
              Status
            </Grid>
            <Grid
              item
              md={5}
              sm={5}
              xs={5}
              className='f-14 fw-500 p-4'
              style={{ wordWrap: 'break-word' }}
            >
              Note
            </Grid>
            <Grid item md={2} sm={2} xs={2} className='f-14 fw-500 p-4'>
              Date
            </Grid>
          </Grid>
          <div style={{ overflowY: 'scroll', height: '500px' }}>
            {historyDetails?.length ? (
              (historyDetails?.reverse() || [])?.map((note) => {
                return (
                  <Grid container className='note-container color-g'>
                    <Grid item md={3} sm={3} xs={3} className='f-13 p-4'>
                      {note?.created_by}
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={2}
                      xs={2}
                      className='f-13 p-4'
                      style={{ wordWrap: 'break-word' }}
                    >
                      {note?.approval ? 'Verified' : 'Rejected'}
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sm={5}
                      xs={5}
                      className='f-13 p-4'
                      style={{ wordWrap: 'break-word' }}
                    >
                      {note?.note?.length ? note?.note : '-'}
                    </Grid>

                    <Grid item md={2} sm={2} xs={2} className='f-13 p-4'>
                      {note?.created_at}
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid item md={3} className='f-13 p-4 d-flex justify-center color-g mt-16'>
                No history available.
              </Grid>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </Drawer>
  );
};

export default SourceDetailsDrawer;
