import axios from 'axios';
import _, { cloneDeep } from 'lodash';
import { resources } from '../i18n';
import { geocodeByAddress } from 'react-places-autocomplete';
import {
  DocElementType,
  MAILING_ADDRESS_ADDRESS,
  MAILING_ADDRESS_CITY,
  MAILING_ADDRESS_STATE,
  MAILING_ADDRESS_ZIP,
  SSN_FIELDS,
} from './constants';
import { addressVerification } from '../services/order/benutech';

export const randomUUID = () => {
  let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
  let result = [u.substr(0, 8), u.substr(8, 4), '4000-8' + u.substr(13, 3), u.substr(16, 12)].join(
    '-'
  );
  return result;
};

export const sortElements = (data) => {
  const groupedElements = _.orderBy(data, ['parent_element', 'parent_value', 'id']);
  const outputArray = [];

  groupedElements.forEach((item) => {
    const idx = outputArray.findIndex((elem) => elem.element_id === item.element_id);
    const children =
      groupedElements.filter((child) => child.parent_element === item.element_id) || [];
    if (idx === -1) {
      outputArray.push(item);
      if (children?.length) outputArray.push(...children);
    } else {
      if (children?.length) outputArray.splice(idx + 1, 0, ...children);
    }
  });
  return outputArray;
};

export const errorChecker = (element, value, mask = '') => {
  let tempValue = value;
  let regexPattern = element.match_pattern;
  if (regexPattern === '^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,4}$') regexPattern = null;
  if (mask && tempValue) {
    const emptyMask = mask.replace(/9/g, '_');
    if (emptyMask === tempValue) {
      tempValue = '';
    }
  }
  if (element.required && !tempValue) {
    return { isError: true, message: 'This field is required' };
  } else if (regexPattern && tempValue) {
    const re = new RegExp(regexPattern);
    if (!re.test(tempValue)) {
      return { isError: true, message: 'Please provide appropriate value for this field.' };
    } else if (element.element_name === 'core_cc_exp') {
      const d = new Date();
      const m = parseInt(tempValue.split('/')[0]),
        y = parseInt(tempValue.split('/')[1]);

      if (
        m > 12 ||
        y < d.getFullYear() ||
        y > d.getFullYear() + 100 ||
        (m < d.getMonth() + 1 && y === d.getFullYear())
      ) {
        return { isError: true, message: 'Please provide appropriate value for this field.' };
      } else {
        return { isError: false, message: '' };
      }
    } else {
      return { isError: false, message: '' };
    }
  } else {
    return { isError: false, message: '' };
  }
};

export const areAllElementsSame = (arr) => {
  const uniqueElements = _.uniq(arr);
  return uniqueElements.length === 1;
};

export const verifyElement = (sourceData, value) => {
  let verified = false;
  let message = 'Source found but data does not match';
  let value1 = _.toLower(_.trim(value));
  let foundedSource = '';
  if (sourceData?.verification_source?.length > 0) {
    for (let source of sourceData?.verification_source) {
      for (let source_value of source.value) {
        if (_.isEqual(value1, _.toLower(_.trim(source_value)))) {
          verified = true;
          message = `Data matched with ${source.source} source`;
          foundedSource = source.source;
          break;
        }
      }
    }
  }
  return { verified, message, foundedSource };
};

export const generateAvatarText = (fullName) => {
  const initials = fullName
    ?.split(' ')
    ?.map((name) => name.charAt(0).toUpperCase())
    ?.join('');
  return initials;
};

// Common function that convert static url to dynamic url using param values
export const parseStaticURLToDynamicValues = (breadcrumb, values) => {
  let staticURL = breadcrumb?.url;
  let title = breadcrumb?.title;
  const staticParts = staticURL.split('/');
  let constructedUrl = '';
  for (let i = 0; i < staticParts.length; i++) {
    let part = staticParts[i];
    if (part.startsWith(':')) {
      part = values[part.slice(1)];
    }
    constructedUrl = constructedUrl + (part?.length ? `/${part}` : '');
  }
  return { url: constructedUrl, title: title.startsWith(':') ? values[title.slice(1)] : title };
};

export const fetchSourceData = (element, use = 'auto') => {
  let sources = [];
  if (element.verification?.verification_source?.length) {
    for (let source of element.verification?.verification_source) {
      for (let source_value of source?.value) {
        sources.push({
          source: source.source,
          value: source_value,
          is_verified: source?.is_verified ? true : false,
          is_from_benutech: source.is_from_benutech === 'yes' ? true : false,
        });
      }
    }
    if (element.element_id === 'a1ca9a9c-e068-42b9-89f7-e83e810fce12')
      if (use === 'auto') {
        sources = sources.filter((source) => source.is_from_benutech);
      } else if (use === 'admin') {
        sources = sources;
      } else {
        sources = sources.filter((source) => !source.is_from_benutech);
      }
  }
  return sources;
};

export const convertedValue = (value) => {
  if (value == true) {
    return 'Yes';
  } else {
    return 'No';
  }
};

export const isValidDate = (dateString) => {
  // Replace slashes with dashes in the date string
  const formattedDateString = dateString.replace(/\//g, '-');

  // Try to create a new Date object from the formatted string
  const date = new Date(formattedDateString);
  // Check if the date is a valid date and the formatted string representation of the date is equal to the input
  return {
    isValid: !isNaN(date.getTime()) && formattedDateString === date.toISOString().split('T')[0],
    date,
  };
};

export const findElement = (section, element_id) => {
  if (!section?.groups?.length) return {};
  let elem = {};
  const answer = (element) => {
    if (element?.element_id == element_id) return (elem = element);
    if (element.options.length) {
      for (const option of element?.options) {
        for (const subElement of option?.elements) {
          answer(subElement);
        }
      }
    }
  };
  for (const grp of section?.groups) {
    for (const element of grp?.elements) {
      if (elem?.element_id) return elem;
      answer(element);
    }
  }
  return elem;
};

export const updateJsonIds = (json) => {
  let tempJson = cloneDeep(json);
  const answer = (elem) => {
    elem.element_id = randomUUID();
    if (elem.options.length) {
      for (const option of elem?.options) {
        for (const subElement of option?.elements) {
          answer(subElement);
        }
      }
    }
  };
  for (const elem of tempJson) {
    answer(elem);
  }
  return tempJson;
};

export const modifyJson = (json, elements, section_id, group_id, element_id, option_id) => {
  let tempJson = json;
  let sectionIdx, groupIdx, elementIdx, optionIdx;
  sectionIdx = json.sections.findIndex((section) => section.section_id === section_id);
  if (sectionIdx !== undefined && sectionIdx !== -1) {
    groupIdx = json.sections[sectionIdx].groups.findIndex((group) => group.group_id === group_id);
  } else {
    console.log('Section not found');
    return false;
  }
  if (groupIdx == undefined || groupIdx == -1) {
    console.log('Group not found');
    return false;
  }
  if (element_id && option_id) {
    elementIdx = json.sections[sectionIdx].groups[groupIdx].elements.findIndex(
      (element) => element.element_id === element_id
    );
    if (elementIdx == undefined || elementIdx == -1) {
      console.log('Element not found', elementIdx);
      return false;
    }
    optionIdx = json.sections[sectionIdx].groups[groupIdx].elements[elementIdx].options.findIndex(
      (opt) => opt.option_id === option_id
    );
    if (optionIdx == undefined || optionIdx == -1) {
      console.log('Option not found');

      return false;
    }
    tempJson.sections[sectionIdx].groups[groupIdx].elements[elementIdx].options[
      optionIdx
    ].elements.push(...elements);
  } else {
    tempJson.sections[sectionIdx].groups[groupIdx].elements.push(...elements);
  }
  return tempJson;
};

export const formatDateToYYYYMMDD = (date) => {
  // Extract the components of the date
  const year = date.getFullYear();
  // JavaScript months are 0-indexed, so add 1 to get the correct month
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Zero-padding month
  const day = String(date.getDate()).padStart(2, '0'); // Zero-padding day

  // Concatenate the components to form the desired format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const constructLanguageJson = async (arr) => {
  try {
    const API_URL = 'https://translation.googleapis.com/language/translate/v2?key=';
    const languages = [...arr];
    const englishObj = {
      language: 'Language',
      languageMessage: `You can view the participant form in various available languages.`,
      propertyAddress: 'Property Address',
      orderDetails: 'Order Details',
      fileNumber: 'File Number',
      title: 'Title / Escrow Company',
      contact: 'Contact Information',
      note: 'Note : Your progress in previous sections is automatically saved. However, the details of the current section will not be saved automatically unless you click on the NEXT STEP button at the end of the section.',
      notePOA: `Note: A power of attorney, also known as an “attorney-in-fact” is used in many real estate
            transactions to allow someone else to be able to complete closings or giving someone else
            the power to sell your properties.`,
      noteAdditionalService: `Note : You can add an additional service and its relevant details if it is not listed
                here.`,
      addParticipant: 'Add Participant',
      addParticipantConfirmation: ` <b>Would you like to add an additional participant to this transaction?</b> You will be
              prompted to provide basic information <b>(Name, Email, Phone Number, SSN)</b> for any
              additional participants.`,

      serviceLabel: `Label For Service`,
      serviceLabelRequired: `Service Label is required`,
      addHOA: 'Add Another HOA',
      addLoan: 'Add Another Loan',
      addBuyer: 'Add Another Buyer',
      addBorrower: 'Add Another Borrower',
      noteHOA: `<b>Would you like to add an additional HOA for this transaction?</b> You will be
              prompted to provide basic information <b>(Association Name, Management Company, Association Dues)</b> for any
              additional HOA.`,
      noteLoan: `<b>Would you like to add an additional Loan for this transaction?</b> You will be
              prompted to provide basic information <b>(Lender Name, Loan Number, Approximate Balance)</b> for any
              additional Loans.`,

      noteBuyer: `<b>Would you like to add an additional buyer for this transaction?</b> You will be
              prompted to provide basic information <b>(Buyer Name, Email, Phone, DOB, etc.)</b> for any
              additional buyer.`,
      noteBorrower: `<b>Would you like to add an additional borrower for this transaction?</b> You will be
              prompted to provide basic information <b>(Name, Email, Phone Number, SSN)</b> for any
              additional borrower.`,
      lastSection: 'Signature & Documents',
      participantSignature: 'Participant Signature',
      signRequired: 'Please add signature to proceed further.',
      additionalSignature: `Additional Participant's Signature`,
      lastStepMessage1: `  These document(s) will be automatically signed electronically at the end.`,
      lastStepMessage2: `The form will NOT be submitted until ALL documents are reviewed and the submit
                    button below is clicked.`,

      congratulations: 'Congratulations',
      download: `DOWNLOAD FINAL FORM`,
      submissionMessage1: `Completed. Your documents have been successfully completed.`,
      submissionMessage2: `You are all set. We have sent an email to your spouse and/or any additional parties for their signatures. Once the signatures are complete, the documents will be forwarded to`,
      submissionMessage3: ` Secured and authenticated by VoxturVerify California Inc ™`,
      somethingWrong: 'Something went wrong!',
      creditCardLabel: 'Credit Card Information',
      creditCardMessage: `By providing your card information, you allow localhost:3000 to charge your card for
                future payments in accordance with their terms.`,
      creditCardSuccess: `Credit card has been added successfully.`,
      pendingReview: 'Pending Review',
      docReviewed: 'You have reviewed this document.',
    };
    let convertedArr = [];
    let constructedJson = {};
    for (const key in englishObj) {
      convertedArr.push({ label: key, value: englishObj[key] });
    }
    let payloadForTranslate = convertedArr.map((val) => (val.value ? val.value : ''));
    for (const lng of languages) {
      const body = {
        q: payloadForTranslate,
        target: lng?.value,
      };
      const response = await axios.post(API_URL, body);
      const translations = response.data.data.translations.map(
        (translation) => translation.translatedText
      );

      let tempObj = {};
      const translatedCombinedArr = translations.map((translatedLabel, index) => ({
        label: convertedArr?.[index]?.label,
        value: translatedLabel,
      }));
      translatedCombinedArr.forEach((tra) => (tempObj[tra.label] = tra.value));
      constructedJson[lng?.value] = { translation: tempObj };
    }
    console.log(constructedJson);
    return constructedJson;
  } catch (e) {
    console.error(e);
  }
};

export const modifyResourceJson = (languages, key) => {
  let tempData = { ...resources };
  languages.forEach((language) => {
    if (tempData[language.value]) tempData[language.value].translation[key] = language.label;
  });
  return tempData;
};
// export const splitTexts = (texts, maxChars = 800) => {
//   let result = [];
//   let currentArray = [];
//   let currentLength = 0;

//   for (let text of texts) {
//     let textLength = text.length;
//     if (currentLength + textLength <= maxChars) {
//       currentArray.push(text);
//       currentLength += textLength;
//     } else {
//       result.push(currentArray);
//       currentArray = [text];
//       currentLength = textLength;
//     }
//   }

//   if (currentArray.length > 0) {
//     result.push(currentArray);
//   }

//   return result;
// };

export const findElements = (elements) => {
  let tempJson = cloneDeep(elements);
  let flatElements = [];
  let options = [];
  const answer = (elem) => {
    flatElements.push({ id: elem.element_id, label: elem.label });
    if (elem.options.length) {
      for (const option of elem?.options) {
        options.push({ id: option.option_id, label: option.option_name });
        for (const subElement of option?.elements) {
          answer(subElement);
        }
      }
    }
  };
  for (const elem of tempJson) {
    answer(elem);
  }
  return { elements: flatElements, options };
};
export const splitTexts = (inputArray) => {
  const result = [];
  for (let i = 0; i < inputArray.length; i += 30) {
    result.push(inputArray.slice(i, i + 30));
  }
  return result;
};
export const findAddressComponent = async (value) => {
  try {
    const results = await geocodeByAddress(value);
    const addressComponents = results[0].address_components;
    const streetAddress = addressComponents
      .filter((comp) => comp.types.includes('street_number') || comp.types.includes('route'))
      .map((comp) => comp.short_name)
      .join(' ');
    // Address description
    const city = addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || '';
    const state =
      addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))
        ?.short_name || '';
    const zip =
      addressComponents.find((comp) => comp.types.includes('postal_code'))?.long_name || '';
    const fullAddress = streetAddress + ',' + city + ',' + state + ',' + zip;
    return {
      site_address: streetAddress,
      site_city: city,
      site_state: state,
      site_zip: zip,
      // site_unit: unit,
      site_full_address: fullAddress,
    };
  } catch (error) {
    console.error('Error selecting address:', error);
    return false;
  }
};
export const matchAddress = async (mobyAddress, userAddress, token) => {
  try {
    if (!mobyAddress) return {};
    let mobyAddressDestructured = await findAddressComponent(mobyAddress);
    if (!mobyAddressDestructured) return {};

    if (!Object.keys(userAddress || {}).length) return {};
    const addressVerificationObjMoby = await addressVerification(mobyAddressDestructured, token);
    if (!addressVerificationObjMoby?.success)
      return {
        [MAILING_ADDRESS_ADDRESS]: true,
        [MAILING_ADDRESS_STATE]: true,
        [MAILING_ADDRESS_CITY]: true,
        [MAILING_ADDRESS_ZIP]: true,
        state: false,
      };
      const verifiedMobyAddress = addressVerificationObjMoby?.data?.[0];
      const addressVerificationObjUser = await addressVerification(userAddress, token);
      if (!addressVerificationObjUser?.success)
        return {
          [MAILING_ADDRESS_ADDRESS]: true,
          [MAILING_ADDRESS_STATE]: true,
          [MAILING_ADDRESS_CITY]: true,
          [MAILING_ADDRESS_ZIP]: true,
          state: verifiedMobyAddress?.sa_site_state
            ? verifiedMobyAddress?.sa_site_state?.toUpperCase()
            : false,
          moby_address: mobyAddressDestructured,
        };
      const verifiedParticipantAddress = addressVerificationObjUser?.data?.[0];

      if (!verifiedMobyAddress || !verifiedParticipantAddress)
        return {
          [MAILING_ADDRESS_ADDRESS]: true,
          [MAILING_ADDRESS_STATE]: true,
          [MAILING_ADDRESS_CITY]: true,
          [MAILING_ADDRESS_ZIP]: true,
          state: verifiedMobyAddress?.sa_site_state
            ? verifiedMobyAddress?.sa_site_state?.toUpperCase()
            : false,
          moby_address: !verifiedMobyAddress ? {} : mobyAddressDestructured,
        };
    const isMultiple = addressVerificationObjUser?.multiple || addressVerificationObjMoby?.multiple;
    return {
      [MAILING_ADDRESS_ADDRESS]: isMultiple
        ? true
        : !(
            verifiedMobyAddress?.sa_site_street_name ===
              verifiedParticipantAddress?.sa_site_street_name &&
            verifiedMobyAddress?.sa_site_house_nbr === verifiedParticipantAddress?.sa_site_house_nbr
          ),
      [MAILING_ADDRESS_STATE]:
        verifiedMobyAddress?.sa_site_state !== verifiedParticipantAddress?.sa_site_state,
      [MAILING_ADDRESS_CITY]:
        verifiedMobyAddress?.sa_site_city !== verifiedParticipantAddress?.sa_site_city,
      [MAILING_ADDRESS_ZIP]:
        verifiedMobyAddress?.sa_site_zip !== verifiedParticipantAddress?.sa_site_zip,
      state: verifiedMobyAddress?.sa_site_state?.toUpperCase(),
      moby_address: mobyAddressDestructured,
      is_multiple: isMultiple,
    };
  } catch (error) {
    console.log(error.message);
    return {};
  }
};

export const maskEmail = (email) => {
  return email.replace(/(^\w)(.*)(@\w+\.\w+)/, (match, firstChar, hiddenPart, domain) => {
    const masked = hiddenPart.replace(/[^.]/g, '*');
    return `${firstChar}${masked}${domain}`;
  });
};

export const snakeToTitleCase = (snakeStr) => {
  return snakeStr
    .split('_') // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(' '); // Join words with a space
};

export const checkFormFieldError = (question, isAdditionalSeller, participantNo) => {
  let tempJson = cloneDeep(question);
  let errorSections = [];
  const answer = (elem, section) => {
    if (
      (elem.element_type === DocElementType.TextField ||
        elem.element_type === DocElementType.TextArea ||
        elem.element_type === DocElementType.SelectBox ||
        elem.element_type === DocElementType.RadioButton ||
        elem.element_type === DocElementType.SocialNumber ||
        elem.element_type === DocElementType.SSelectBox ||
        elem.element_type === DocElementType.DateField ||
        elem.element_type === DocElementType.Checkbox) &&
      elem.display &&
      !SSN_FIELDS.includes(elem.element_name)
    ) {
      const error = errorChecker(elem, elem?.user_value);
      if (error?.isError) {
        const isAvailable = errorSections?.find((sec) => sec?.section_id === section.section_id);
        if (!isAvailable) {
          errorSections.push(section);
        }
      }
    }
    if (elem.options.length) {
      for (const option of elem?.options) {
        if (elem.user_value && elem.user_value === option.option_name) {
          for (const subElement of option?.elements) {
            if (!isAdditionalSeller && subElement?.participant_no && subElement?.participant_no > 1)
              continue;
            if (subElement?.participant_no && subElement?.participant_no != participantNo) continue;
            answer(subElement, section);
          }
        }
      }
    }
  };
  for (const section of tempJson?.sections) {
    if (section?.is_hidden) continue;
    for (const group of section?.groups) {
      if (group.is_hidden) continue;

      for (const element of group.elements) {
        if (!isAdditionalSeller && element?.participant_no && element?.participant_no > 1) {
          continue;
        }
        if (element?.participant_no && element?.participant_no != participantNo) {
          continue;
        }
        answer(element, section);
      }
    }
  }
  return errorSections;
};

export const updateSourceData = (que, sectionIdx, sourceData) => {
  let elemData = cloneDeep(que);
  const answer = (element) => {
    if (sourceData[element?.element_id]) {
      // const idx = element?.verification?.verification_source?.findIndex(
      //   (prev) => prev.source === src
      // );
      element.value_from_bank_source = sourceData?.[element?.element_id];
      // if (idx !== -1) {
      //   element.verification.verification_source[idx] = {
      //     source: src,
      //     value: [sourceData?.[element?.element_id]],
      //     is_from_benutech: 'no',
      //     // is_verified: true,
      //   };
      //   // element.is_verified_with_source = true;
      // } else {
      //   // element.is_verified_with_source = true;
      //   element.verification.verification_source = [
      //     ...(element.verification.verification_source || []),
      //     {
      //       source: src,
      //       value: [sourceData?.[element?.element_id]],
      //       is_from_benutech: 'no',
      //       // is_verified: true,
      //     },
      //   ];
      // }
    }
    if (element.options.length) {
      for (const option of element?.options) {
        for (const subElement of option?.elements) {
          answer(subElement);
        }
      }
    }
  };
  for (const grp of elemData?.sections?.[sectionIdx]?.groups) {
    for (const element of grp?.elements) {
      answer(element);
    }
  }
  return elemData;
};

export const findParentElement = (group, element_id) => {
  if (!group?.elements?.length) return null;

  let parentElem = null;
  let optionId = null;

  const search = (element, parent, option_id) => {
    // If the element is the one we are looking for, return the parent
    if (element?.element_id == element_id) {
      parentElem = parent;
      optionId = option_id;
      return;
    }

    // Traverse the options and their elements if available
    if (element.options?.length) {
      for (const option of element?.options) {
        for (const subElement of option?.elements) {
          search(subElement, element, option?.option_id); // Pass current element as the parent
          if (parentElem) return; // Early return if parent is found
        }
      }
    }
  };

  // Traverse each group and its elements
  for (const element of group?.elements) {
    search(element, null, null); // Null since top-level elements don't have a parent
    if (parentElem) return { parent: parentElem, optionId };
  }

  return { parent: parentElem, optionId }; // Return the parent element or null if not found
};

export const findSectionAndGroupForElement = (sections, elementId) => {
  let result = {
    section: null,
    group: null,
  };

  // Recursive helper function to search for the element within options and elements
  const searchElement = (element, section, group) => {
    // If the element matches the given elementId, store the current section and group
    if (element?.element_id === elementId) {
      result = { section, group };
      return true;
    }

    // Traverse the options and their elements if they exist
    if (element?.options?.length) {
      for (const option of element.options) {
        for (const subElement of option?.elements || []) {
          if (searchElement(subElement, section, group)) {
            return true; // Stop further traversal if the element is found
          }
        }
      }
    }

    return false;
  };

  // Traverse each section and its groups
  for (const section of sections) {
    for (const group of section?.groups || []) {
      for (const element of group?.elements || []) {
        if (searchElement(element, section, group)) {
          return result; // Return the result if the element is found
        }
      }
    }
  }

  return result; // Return null if no matching element is found
};
