import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import WelcomeModal from '../../../components/questions/invite/welcome';
import FacecaptureModal from '../../../components/questions/invite/facecapturemodal';
import LogincodeModal from '../../../components/questions/invite/logincodemodal';
import {
  initiateLogin,
  loginParticipant,
  resendCode,
  skipMFA,
} from '../../../services/questions/invite';
import { useDispatch, useSelector } from 'react-redux';
import { clearParticipant, login } from '../../../store/slices/authSlice';
import { toast } from 'react-toastify';
import { getCompanyData, getOrderData } from '../../../services/questions/home';
import {
  addCompanyDetails,
  addOrderDetails,
  clearQuestionSlice,
  removeBankDetails,
  removeCurrentStep,
} from '../../../store/slices/questionSlice';
import { Helmet } from 'react-helmet';
import Loader from '../../../components/common/Loader';
import { checkAuthToken } from '../../../services/auth/auth';

const Invite = () => {
  const { code, participantNo } = useParams();
  const authData = useSelector((state) => state?.authSlice);
  const order_part_participant_id = useSelector(
    (state) => state.questionSlice.questionDetails
  )?.find((que) => que.tx_id === code)?.order_part_participant_id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url = window.location.origin;

  const [isFaceCaptureModal, setIsFaceCaptureModal] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [isWelcomeModal, setIsWelcomeModal] = useState(true);
  const [otpCountDown, setOtpCountdown] = useState(false);

  const companyDetails = useSelector((state) => state.questionSlice.companyDetails)?.find(
    (company) => company.tx_id === code
  )?.company;

  useEffect(() => {
    if (!companyDetails?.name) getCompanyDetails();
  }, []);

  useEffect(() => {
    if (participantNo && +participantNo !== 1) {
      dispatch(
        removeCurrentStep({
          tx_id: code,
        })
      );
      dispatch(
        removeBankDetails({
          tx_id: code,
        })
      );
    }
  }, [participantNo]);

  useEffect(() => {
    checkAuthenticated();
  }, []);

  const checkAuthenticated = async () => {
    let user_id = authData?.[code]?.id;
    let token = authData?.[code]?.token;

    if (!token || !user_id) {
      return;
    }
    let payload = { user_id };
    payload.participant_no = participantNo || 1;
    if (order_part_participant_id) payload.order_part_participant_id = order_part_participant_id;
    const res = await checkAuthToken(payload, token);
    if (res?.success) {
      if (participantNo) {
        navigate(`/questions/home/${code}/${participantNo}`);
      } else {
        navigate(`/questions/home/${code}`);
      }
    }
  };

  const startAuthProcess = async () => {
    setLoading(true);
    const response = await initiateLogin(code, participantNo || 1, url);
    if (response?.success) {
      dispatch(clearParticipant());
      dispatch(clearQuestionSlice());
      localStorage.removeItem('mobyResponse');
      dispatch(
        login({
          data: {
            ...response?.response?.user,
            last_four_mobile: response?.response?.last_four_mobile,
          },
          code: code,
        })
      );
      setIsWelcomeModal(false);
      setIsLoginModal(true);
    } else {
      if (response?.response?.domain?.length) {
        toast.error(
          `You are not allowed to log in with this URL. We are redirecting you to your client's URL.`
        );

        setTimeout(() => {
          window.location.replace(`${response?.response?.domain}/questions/invite/${code}`);
          setLoading(false);
        }, 5000);

        return;
      }
      toast.error(response?.error || response?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  const resendCodeProcess = async () => {
    setResendOtpLoading(true);
    const response = await resendCode(code, participantNo || 1);
    if (response?.success) {
      toast.success(response?.message);
      setOtpCountdown(true);
    } else {
      toast.error(response?.error || response?.message || 'Something went wrong!');
    }
    setResendOtpLoading(false);
  };

  const skipToMFA = async () => {
    setLoading(true);
    const response = await skipMFA(code);
    if (response?.success) {
    } else {
      // error handle
    }
    setLoading(false);
  };

  const getCompanyDetails = async () => {
    setCompanyLoading(true);
    const data = await getCompanyData(code);
    if (data.success) {
      dispatch(addCompanyDetails({ tx_id: code.toString(), company: data?.response }));
      // dispatch(
      //   configuration({
      //     url,
      //     data: { ...data?.response },
      //   })
      // );
    }
    setCompanyLoading(false);
  };

  const getOrderInfo = async (token) => {
    setLoading(true);
    const orderData = await getOrderData(code, token);
    if (orderData?.success) {
      dispatch(addOrderDetails({ tx_id: code, order: orderData?.response }));
    }
    setLoading(false);
  };

  const loginUser = async (data) => {
    setLoading(true);
    const resData = await loginParticipant({ ...data, participant_no: participantNo || 1 });
    if (resData?.success) {
      dispatch(
        login({
          data: { ...resData?.response?.user, token: resData?.response?.token },
          code: code,
        })
      );
      // getCompanyDetails(resData?.response?.user?.comapny_id, resData?.response?.token);
      getOrderInfo(resData?.response?.token);
      if (participantNo) {
        navigate(`/questions/home/${code}/${participantNo}`);
      } else {
        navigate(`/questions/home/${code}`);
      }
    } else {
      toast.error(resData?.error || resData?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  return (
    <div>
      <Helmet>
        <title>Invite</title>
      </Helmet>
      {(companyLoading || resendOtpLoading) && <Loader />}
      {!companyLoading && (
        <WelcomeModal
          startAuth={startAuthProcess}
          loading={loading}
          isWelcomeModal={isWelcomeModal}
          logoUrl={companyDetails?.company_logo_url}
          companyName={companyDetails?.company_name}
        />
      )}
      <FacecaptureModal
        open={isFaceCaptureModal}
        setIsFaceCaptureModal={setIsFaceCaptureModal}
        skipToMFA={skipToMFA}
        setIsLoginModal={setIsLoginModal}
        onSubmit={loginUser}
        loading={loading}
      />
      <LogincodeModal
        open={isLoginModal}
        resendCodeProcess={resendCodeProcess}
        onSubmit={loginUser}
        loading={loading}
        otpCountDown={otpCountDown}
        setOtpCountdown={setOtpCountdown}
      />
    </div>
  );
};

export default Invite;
