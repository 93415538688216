import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Chip,
  Alert,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { cloneDeep, groupBy } from 'lodash';
import Loader from '../../../components/common/Loader';
import {
  DL_NUMBER_FIELD,
  DL_STATE_FIELD,
  DL_VERIFICATION_FIELDS,
  DocElementType,
  ID_MAPPING,
  IS_INDIVIDUAL_ENTITY,
  MAILING_ADDRESS_ADDRESS,
  MAILING_ADDRESS_CITY,
  MAILING_ADDRESS_QUESTION,
  MAILING_ADDRESS_STATE,
  MAILING_ADDRESS_ZIP,
  SELLER_FIRST_NAME,
  SELLER_LAST_NAME,
  SOI_SECTION_ID,
  SSN_FIELDS,
} from '../../../utils/constants';
import { ErrorOutline, CheckCircleOutline, Visibility, VerifiedUser } from '@mui/icons-material';
import { getParticipant, verifyElement } from '../../../services/admin/participantForms';
import VerificationConfirmationModal from '../../../components/admin/participantForms/VerificationConfirmationModal';
import { convertedValue, fetchSourceData, findElement, matchAddress } from '../../../utils/helpers';
import SourceDetailsDrawer from '../../../components/admin/participantForms/SourceDetailsDrawer';
import moment from 'moment';
import {
  downloadDocumentAdmin,
  downloadOtherDocuments,
  getDocList,
  getDocumentDetails,
} from '../../../services/questions/home';
import DocumentViewer from '../../../components/common/DocumentViewer';
import AutoCompleteModal from '../../../components/admin/participantForms/AutoCompleteModal';
import DocumentLink from './DocumentLink';
import { getOrder, getSSN, manualVerification } from '../../../services/order/order';
import { getDLDetails } from '../../../services/questions/moby';
import CommonModal from '../../../components/questions/home/CommonModal';

// const sellerDocs = [
//   {
//     label: 'Privacy Policy',
//     name: 'privacy_policy',
//   },
//   {
//     label: 'Third Party Authorization',
//     name: 'third_party_auth',
//   },
//   {
//     label: 'Release Authorization',
//     name: 'release_authorization',
//   },
//   {
//     label: 'Disbursement Seller Proceeds',
//     name: 'disbursement_seller_proceeds',
//   },

//   {
//     label: 'Demand Request 1',
//     name: 'demand_request_1',
//   },
// ];

// const buyerDocs = [
//   {
//     label: 'Disbursement Buyer Proceeds',
//     name: 'disbursement_buyer_proceeds',
//   },
// ];

// const borrowerDocs = [
//   {
//     label: 'Privacy Policy',
//     name: 'privacy_policy',
//   },
//   {
//     label: 'Third Party Authorization',
//     name: 'third_party_auth',
//   },
//   {
//     label: 'Release Authorization',
//     name: 'release_authorization',
//   },
//   {
//     label: 'Demand Request 1',
//     name: 'demand_request_1',
//   },
// ];

const ViewFormDetails = () => {
  const { participantId, id } = useParams();
  const { pathname } = useLocation();
  const { admin } = useSelector((state) => state?.authSlice);
  const { order } = useSelector((state) => state?.authSlice);
  const user =
    pathname.split('/')[1] === 'admin'
      ? admin?.first_name + ' ' + admin?.last_name
      : order?.client_name && !order?.parent_id
      ? order?.client_name
      : order?.first_name + ' ' + order?.last_name;
  const token = pathname.split('/')[1] === 'admin' ? admin?.token : order?.token;
  const [loading, setLoading] = useState(false);
  const [elementFetchLoading, setElementFetchLoading] = useState(false);
  const [dlLoading, setDlLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [allElements, setAllElements] = useState([]);
  const [queJson, setQueJson] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [curData, setCurData] = useState({});
  const [details, setDetails] = useState({});
  const [docs, setDocs] = useState([]);
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [autoCompleteModalOpen, setAutoCompleteModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [originalUrl, setOriginalUrl] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [dlConfirmationModalOpen, setDlConfirmationModalOpen] = useState(false);
  const [statusData, setStatusData] = useState('');
  const [fullSSNs, setFullSSNs] = useState({});
  const [dlDetails, setDlDetails] = useState();
  const [orderDetails, setOrderDetails] = useState({});
  const [isEntity, setIsEntity] = useState(false);
  const isCompleted = details?.status === 'completed' ? true : false;
  const bankAccountClient = order?.is_bank_account_client;

  const ValidationTextField = styled(TextField)(({ helperText }) => ({
    '& .Mui-error': {
      color: acquireValidationColor(helperText),
    },
    '& .MuiFormHelperText-root': {
      color: `${acquireValidationColor(helperText)} !important`,
    },

    // '& .MuiOutlinedInput-root': {
    //   '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    //     borderColor: acquireValidationColor(helperText),
    //   },
    // },
  }));

  const acquireValidationColor = (message) => {
    if (message === 'Source found but data does not match') {
      return 'orange';
    } else {
      return 'green';
    }
  };

  useEffect(() => {
    if (queJson?.sections?.length) fetchAllElements();
  }, [queJson, dlDetails]);

  useEffect(() => {
    if (participantId) fetchDetails();
  }, [participantId]);

  useEffect(() => {
    if (Object.keys(allElements || {})?.length) {
      const pendingFields = getPendingFields();
      if (!pendingFields?.length && details?.data_verify_status !== 'completed')
        setAutoCompleteModalOpen(true);
    }
  }, [allElements]);

  useEffect(() => {
    if (details?.order_participant?.order_part_participant_id) {
      getDocs(details?.order_participant?.order_part_participant_id, details?.order_participant_id);
      fetchDocuments();
    }
  }, [details]);

  useEffect(() => {
    if (details?.participant_uuid) {
      getDL();
    }
  }, [details?.participant_uuid]);

  const getDL = async () => {
    setDlLoading(true);
    const res = await getDLDetails(token, details?.participant_uuid);

    if (Object.keys(res?.response?.transaction_response || {})?.length) {
      setDlDetails(res?.response);
    }
    setDlLoading(false);
  };

  const fetchDetails = async () => {
    setLoading(true);
    const orderRes = await getOrder(id, token);
    if (orderRes.success) {
      setOrderDetails({ ...orderRes.response });
    } else {
      return toast.error(orderRes.message);
    }
    const res = await getParticipant(participantId, token);
    if (res.success) {
      setDetails({ ...res.response });
      setQueJson(res.response?.cached_questions_json?.question);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const getDocs = async (order_part_participant_id, part_id) => {
    setLoading(true);
    const res = await getDocList(order_part_participant_id, part_id, token, true);
    if (Object.keys(res || {})?.length) {
      let tempDocs = [];
      for (const key in res) {
        tempDocs.push({
          name: res?.[key]?.doc_type,
          label: res?.[key]?.doc_name,
          participant_number: res?.[key]?.participant_number,
        });
      }
      setAdditionalDocs(tempDocs);
    }
    setLoading(false);
  };

  const fetchDocuments = async () => {
    setLoading(true);
    const res = await getDocumentDetails(
      {
        order_id: id,
        order_part_participant_id: details?.order_participant?.order_part_participant_id,
      },
      token
    );
    if (res.success) {
      setDocs(res.response.files || []);
    }
    setLoading(false);
  };
  const getUserAddress = (section) => {
    let userAddress = {};
    const currentOrOtherAddress = findElement(section, MAILING_ADDRESS_QUESTION)?.user_value || '';
    if (!currentOrOtherAddress) return userAddress;
    if (currentOrOtherAddress === 'Other') {
      const address1 = findElement(section, MAILING_ADDRESS_ADDRESS)?.user_value || '';
      const state = findElement(section, MAILING_ADDRESS_STATE)?.user_value || '';
      const city = findElement(section, MAILING_ADDRESS_CITY)?.user_value || '';
      const zip = findElement(section, MAILING_ADDRESS_ZIP)?.user_value || '';
      userAddress = {
        site_address: address1,
        site_city: city,
        site_state: state,
        site_zip: `${zip}`,
        // site_unit: unit,
        site_full_address: address1 + ',' + city + ',' + state + ',' + zip,
      };
    } else {
      userAddress = {
        site_address: orderDetails?.property_address_1,
        site_city: orderDetails?.property_city,
        site_state: orderDetails?.property_state,
        site_zip: `${orderDetails?.property_zipcode}`,
        // site_unit: unit,
        site_full_address:
          orderDetails?.property_address_1 +
          ',' +
          orderDetails?.property_city +
          ',' +
          orderDetails?.property_state +
          ',' +
          orderDetails?.property_zipcode,
      };
    }
    return { userAddress, addressType: currentOrOtherAddress };
  };

  const verifyDLDetails = async () => {
    let dlErrors = {};
    let dlValues = {};
    const section = queJson.sections.find((section) => section.section_id == SOI_SECTION_ID);
    const userAddr = getUserAddress(section)?.userAddress;
    const addressType = getUserAddress(section)?.addressType;
    if (Object.keys(userAddr || {})?.length && dlDetails?.transaction_response?.CustomerAddress) {
      dlErrors = await matchAddress(
        dlDetails?.transaction_response?.CustomerAddress,
        userAddr,
        token
      );
    }
    dlErrors[MAILING_ADDRESS_QUESTION] = dlErrors?.[MAILING_ADDRESS_ADDRESS];
    const firstName = findElement(section, SELLER_FIRST_NAME)?.user_value || '';
    const lastName = findElement(section, SELLER_LAST_NAME)?.user_value || '';
    const idNumber = findElement(section, DL_NUMBER_FIELD)?.user_value || '';
    const idState = findElement(section, DL_STATE_FIELD)?.user_value || '';
    const isError = (val1, val2) => val1 && val2 && val1?.toLowerCase() !== val2?.toLowerCase();
    dlErrors = {
      ...dlErrors,
      [SELLER_FIRST_NAME]: isError(firstName, dlDetails?.transaction_response?.FirstName),
      [SELLER_LAST_NAME]: isError(lastName, dlDetails?.transaction_response?.FirstSurname),
      [DL_NUMBER_FIELD]: isError(idNumber, dlDetails?.transaction_response?.IdentificationNumber),
      [DL_STATE_FIELD]: isError(idState, dlErrors?.state),
    };

    dlValues = {
      [MAILING_ADDRESS_QUESTION]:
        dlErrors?.[MAILING_ADDRESS_QUESTION] && addressType === 'Property'
          ? dlErrors?.moby_address?.site_full_address
          : '',
      [SELLER_FIRST_NAME]: isError(firstName, dlDetails?.transaction_response?.FirstName)
        ? dlDetails?.transaction_response?.FirstName
        : '',
      [SELLER_LAST_NAME]: isError(lastName, dlDetails?.transaction_response?.FirstSurname)
        ? dlDetails?.transaction_response?.FirstSurname
        : '',
      [DL_NUMBER_FIELD]: isError(idNumber, dlDetails?.transaction_response?.IdentificationNumber)
        ? dlDetails?.transaction_response?.IdentificationNumber
        : '',
      [DL_STATE_FIELD]: isError(idState, dlErrors?.state) ? dlErrors?.state : '',
      [MAILING_ADDRESS_ADDRESS]: dlErrors?.[MAILING_ADDRESS_ADDRESS]
        ? dlErrors?.moby_address?.site_address
        : '',
      [MAILING_ADDRESS_STATE]: dlErrors?.[MAILING_ADDRESS_STATE]
        ? dlErrors?.moby_address?.site_state
        : '',
      [MAILING_ADDRESS_CITY]: dlErrors?.[MAILING_ADDRESS_CITY]
        ? dlErrors?.moby_address?.site_city
        : '',
      [MAILING_ADDRESS_ZIP]: dlErrors?.[MAILING_ADDRESS_ZIP]
        ? dlErrors?.moby_address?.site_zip
        : '',
    };
    return { dlErrors, dlValues };
  };
  const fetchAllElements = async () => {
    try {
      setElementFetchLoading(true);
      let elementsData = cloneDeep(queJson);
      let dlErrors = {};
      let dlValues = {};
      // let bankAccountVerified = false;

      // DL validations
      if (
        Object.keys(dlDetails?.transaction_response || {})?.length &&
        dlDetails?.transaction_response?.Approved
      ) {
        let res = await verifyDLDetails();
        dlErrors = res?.dlErrors || {};
        dlValues = res?.dlValues || {};
      }

      // Bank account verification
      // const bankAccountIndex = elementsData?.sections?.findIndex(
      //   (sec) => sec?.section_id === BANK_ACCOUNT_NAME
      // );
      // if (bankAccountIndex && bankAccountIndex !== -1 && isCompleted) {
      //   const bankAcc =
      //     findElement(elementsData?.sections?.[bankAccountIndex], BANK_ACCOUNT_NUMBER)
      //       ?.user_value || '';
      //   if (bankAcc) {
      //     const res = await getBankDetails(token, details?.order_participant_id, bankAcc);
      //     if (res?.response?.status_code === 101) {
      //       bankAccountVerified = true;
      //     }
      //   }
      // }
      let tempElements = [];

      const answer = async (element, sectionName = '', secIdx, grpIdx) => {
        if (
          ![
            DocElementType.Label,
            DocElementType.File,
            DocElementType.Media,
            DocElementType.MediaLink,
            DocElementType.Upload,
            DocElementType.DocumentLink,
          ].includes(element.element_type) &&
          element?.display
        ) {
          let sources = fetchSourceData(element);
          const tempSource = sources?.find((src) => src.is_verified);
          let verifiedSource = tempSource?.source ?? tempSource?.source;
          let txError = null;
          const tx = sources.find((src) => src.source === 'tx');
          let isVerifiedWithSource = element?.is_verified_with_source;

          if (element.element_id === IS_INDIVIDUAL_ENTITY && element.user_value === 'Entity') {
            setIsEntity(true);
          }

          // verification for DL related fields that user has changed

          if (DL_VERIFICATION_FIELDS.includes(element.element_id)) {
            if (isCompleted && tx && !verifiedSource) {
              txError = `The participant has changed the value for the field. Initially, the value was <i>${tx.value}</i> when the order was created.`;
            }
          }

          // verification for DL related fields to check if user input matches with DL response

          if (dlErrors?.[element.element_id] && dlValues?.[element.element_id]?.length)
            txError = `The participant's input value for the field differs from the ${
              dlDetails?.transaction_response?.IdentificationTypeId &&
              ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                : 'Government Issued ID'
            }. The value on the ${
              dlDetails?.transaction_response?.IdentificationTypeId &&
              ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                : 'Government Issued ID'
            } is <i>${dlValues?.[element.element_id]}</i>.`;

          // verification for DL related fields to check if user input matches with DL response

          if (dlErrors?.[element.element_id] && dlValues?.[element.element_id]?.length)
            txError = `The participant's input value for the field differs from the ${
              dlDetails?.transaction_response?.IdentificationTypeId &&
              ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                : 'Government Issued ID'
            }. The value on the ${
              dlDetails?.transaction_response?.IdentificationTypeId &&
              ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                : 'Government Issued ID'
            } is <i>${dlValues?.[element.element_id]}</i>.`;

          if (
            element.element_id === MAILING_ADDRESS_ADDRESS &&
            dlErrors?.[MAILING_ADDRESS_ADDRESS] &&
            !dlErrors?.[MAILING_ADDRESS_ZIP] &&
            dlErrors?.is_multiple
          ) {
            txError = `Multiple addresses were found for this mailing address. There might be an issue with the unit number.`;
          }
          if (
            Object.keys(dlDetails?.transaction_response || {})?.length &&
            dlErrors[element.element_id] === false
          ) {
            isVerifiedWithSource = true;
            verifiedSource =
              dlDetails?.transaction_response?.IdentificationTypeId &&
              ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                : 'Government Issued ID';
          }

          // bank field verification

          if (element?.value_from_bank_source) {
            if (element?.value_from_bank_source === 'no') {
              txError = `Participant's bank account is not verified.`;
            } else if (element?.value_from_bank_source != element.user_value) {
              txError = `Participant's input for this field differ from verified bank account.`;
            } else {
              isVerifiedWithSource = true;
              verifiedSource = 'Lyons';
              sources.push({
                source: 'Lyons',
                value: element?.value_from_bank_source,
                is_verified: true,
                is_from_benutech: false,
              });
            }
          }

          tempElements.push({
            label: element.label,
            element_id: element.element_id,
            element_name: element.element_name,
            participant_number: element.participant_number,
            section_name: sectionName,
            verification: element.verification,
            element_type: element.element_type,
            rejected_by: element?.rejected_by,
            ssn_ein_masked_number: element?.ssn_ein_masked_number
              ? element.ssn_ein_masked_number
              : '',
            user_value:
              element.element_type === DocElementType.Checkbox
                ? convertedValue(element.user_value)
                : element.user_value,
            required: element?.required,
            is_verified_with_source: isVerifiedWithSource ? isVerifiedWithSource : false,
            verified_with_source_by_user_id: element?.verified_with_source_by_user_id
              ? element?.verified_with_source_by_user_id
              : '',
            ssn_part_1: element?.ssn_part_1 ? element.ssn_part_1 : '',
            ssn_part_2: element?.ssn_part_2 ? element.ssn_part_2 : '',
            tx_error: txError ? txError : '',
            history: element?.history || [],
            sources: sources,
            secIdx,
            verifiedSource,
            grpIdx,
          });
        }

        if (element.options.length) {
          for (const option of element.options) {
            if (element?.user_value === option.option_name) {
              for (const subElement of option.elements) {
                await answer(subElement, sectionName, secIdx, grpIdx);
              }
            }
          }
        }
      };
      for (const [secIdx, section] of elementsData?.sections?.entries()) {
        if (section?.is_hidden) continue;
        for (const [grpIdx, group] of section?.groups?.entries()) {
          if (group.is_hidden) continue;
          for (const element of group.elements) {
            await answer(element, section.section_label, secIdx, grpIdx);
          }
        }
      }
      const groupedElements = groupBy(tempElements, 'section_name');
      setAllElements(groupedElements);
      setElementFetchLoading(false);
    } catch (e) {
      setElementFetchLoading(false);
    }
  };

  const onVerify = async (data, isAll = false) => {
    setLoading(true);
    let tempJson = cloneDeep(queJson);

    if (isAll) {
      const answer = async (element) => {
        if (!element?.is_verified_with_source) {
          element.verified_with_source_by_user_id = admin?.user_id || order?.user_id;
          element.rejected_by = null;
          element.history = element?.history?.length
            ? [
                ...element.history,
                {
                  note: '-',
                  created_by: user,
                  approval: 1,
                  created_at: moment(new Date()).format('YYYY-MM-DD'),
                },
              ]
            : [
                {
                  note: '-',
                  created_by: user,
                  approval: 1,
                  created_at: moment(new Date()).format('YYYY-MM-DD'),
                },
              ];
        }
        if (element.options.length) {
          for (const option of element.options) {
            if (element?.user_value === option.option_name) {
              for (const subElement of option.elements) {
                await answer(subElement);
              }
            }
          }
        }
      };
      for (const section of tempJson.sections) {
        for (const grp of section.groups) {
          for (const element of grp.elements) {
            await answer(element);
          }
        }
      }
    } else {
      for (let field of data) {
        let elementFound = false;
        const answer = async (element) => {
          if (element.element_id === field.element_id) {
            element.verified_with_source_by_user_id = admin?.user_id || order?.user_id;
            element.rejected_by = null;
            element.user_value = field.user_value;
            element.history = field.history;
            elementFound = true;
            return;
          }
          if (element.options.length) {
            for (const option of element.options) {
              if (element?.user_value === option.option_name) {
                for (const subElement of option.elements) {
                  await answer(subElement);
                }
              }
            }
          }
        };
        let currentGroup = tempJson?.sections[field?.secIdx]?.groups[field?.grpIdx];
        for (const element of currentGroup.elements) {
          if (elementFound) {
            break;
          }
          await answer(element);
        }
      }
    }
    const res = await verifyElement(
      details?.participant_uuid,
      {
        cached_questions_json: {
          question: cloneDeep(tempJson),
        },
        is_completed: isAll,
      },
      token
    );
    if (!res?.success) {
      toast.error(res.message);
    }
    await fetchDetails();
    setSelectedFields([]);
    setOpen(false);
    setVerificationModalOpen(false);
    setLoading(false);
  };

  const onUnverify = async (data) => {
    setLoading(true);
    let tempJson = cloneDeep(queJson);
    for (let field of data) {
      let elementFound = false;
      const answer = async (element) => {
        if (element.element_id === field.element_id) {
          element.verified_with_source_by_user_id = null;
          element.is_verified_with_source = null;
          element.rejected_by = admin?.user_id || order?.user_id;
          element.history = field.history;
          elementFound = true;
          return;
        }
        if (element.options.length) {
          for (const option of element.options) {
            if (element?.user_value === option.option_name) {
              for (const subElement of option.elements) {
                await answer(subElement);
              }
            }
          }
        }
      };
      let currentGroup = tempJson?.sections[field?.secIdx]?.groups[field?.grpIdx];
      for (const element of currentGroup.elements) {
        if (elementFound) {
          break;
        }
        await answer(element);
      }
    }

    const res = await verifyElement(
      details?.participant_uuid,
      {
        cached_questions_json: {
          question: cloneDeep(tempJson),
        },
        is_completed: false,
      },
      token
    );
    if (!res?.success) {
      toast.error(res.message);
    }
    await fetchDetails();
    setSelectedFields([]);
    setOpen(false);
    setVerificationModalOpen(false);
    setLoading(false);
  };

  const onSelect = (element) => {
    setSelectedFields((fields) => {
      let tempElements = [...fields];
      let foundedIndex = tempElements?.findIndex(
        (field) => field.element_id === element.element_id
      );
      if (foundedIndex === -1) {
        let tempElem = cloneDeep(element);
        tempElem.history = tempElem?.history?.length
          ? [
              ...tempElem.history,
              {
                note: '-',
                created_by: user,
                approval: 1,
                created_at: moment(new Date()).format('YYYY-MM-DD'),
              },
            ]
          : [
              {
                note: '-',
                created_by: user,
                approval: 1,
                created_at: moment(new Date()).format('YYYY-MM-DD'),
              },
            ];
        return [...tempElements, tempElem];
      } else {
        tempElements.splice(foundedIndex, 1);
        return tempElements;
      }
    });
  };

  const downloadAdditionalDocs = async (elemName, participantNum) => {
    setLoading(true);
    if (!isCompleted) {
      toast.error(
        `Documents will be accessible exclusively upon the participant's completion of the form.`
      );
      return setLoading(false);
    }
    let url = '';
    if (elemName === 'core_lender_last_deed_1') {
      const res = await getDocumentDetails(
        {
          order_id: id,
          form_element_name: elemName,
        },
        token
      );
      if (res?.success && res?.response?.files[0]?.url) url = res?.response?.files[0]?.url;
    } else {
      const res = await downloadOtherDocuments(
        details?.participant_uuid,
        token,
        elemName,
        participantNum ? participantNum : 1
      );
      if (res?.success && res?.response?.file) url = res?.response?.file || '';
    }
    if (url) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            setLoading(false);
            toast.error('Document is not available');
          }
          return response.blob();
        })
        .then((blobData) => {
          setLoading(false);
          const blobUrl = URL.createObjectURL(blobData);
          setUrl(blobUrl);
          setOriginalUrl(url);
          setDocModalOpen(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error('Document is not available');
    }
  };
  const getFullSSN = async (element) => {
    setLoading(true);
    const isAdditionalParticipant =
      (element?.participant_number && element?.participant_number !== 1) ||
      element.element_name === 'core_spouse_ssn' ||
      element.element_name === 'borrower_spouse_ssn'
        ? true
        : false;
    let payload = { show_number: true };
    if (isAdditionalParticipant) {
      if (!element?.ssn_part_1?.length || !element?.ssn_part_2?.length) return setLoading(false);
      payload.ssn_part_1 = element?.ssn_part_1;
      payload.ssn_part_2 = element?.ssn_part_2;
    } else {
      payload.order_participant_id = details?.order_participant_id;
    }
    const res = await getSSN(payload, order?.token);
    if (res.success) {
      setFullSSNs((prev) => {
        return { ...prev, [element.element_name]: res?.response?.full_number };
      });
    }
    // if (allElements?.[section]?.[index]?.user_value?.length) {
    //   setFullSSNs((prev) => {
    //     return {
    //       ...prev,
    //       [elem_name]: allElements?.[section]?.[index]?.user_value,
    //     };
    //   });
    // } else {
    //   toast.error('Value not available.');
    // }
    setLoading(false);
  };

  const statementPdf = async () => {
    setLoading(true);
    if (!isCompleted) {
      toast.error(
        `Documents will be accessible exclusively upon the participant's completion of the form.`
      );
      return setLoading(false);
    }
    const urlData = await downloadDocumentAdmin(
      details?.participant_uuid,
      details?.order_participant_id,
      token
    );
    if (urlData?.success) {
      fetch(urlData?.response?.file)
        .then((response) => {
          return response.blob();
        })
        .then((blobData) => {
          const blobUrl = URL.createObjectURL(blobData);
          setUrl(blobUrl);
          setOriginalUrl(urlData?.response?.file);
          setDocModalOpen(true);
        });
    } else {
      toast.error(urlData.message);
    }
    setLoading(false);
  };

  const getExtraDoc = (url) => {
    if (!isCompleted) {
      toast.error(
        `Documents will be accessible exclusively upon the participant's completion of the form.`
      );
      return;
    }
    setLoading(true);
    if (url) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            setLoading(false);
            toast.error('Document is not available');
          }
          return response.blob();
        })
        .then((blobData) => {
          setLoading(false);
          const blobUrl = URL.createObjectURL(blobData);
          setUrl(blobUrl);
          setOriginalUrl(url);
          setDocModalOpen(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error('Document is not available');
    }
  };

  const getPendingFields = () => {
    let tempElements = [];
    let tempAllElements = cloneDeep(allElements);
    for (const section of Object.keys(tempAllElements)) {
      tempElements.push(...tempAllElements[section]);
    }
    tempElements = tempElements.filter(
      (element) => !element.verified_with_source_by_user_id && !element.is_verified_with_source
    );
    return tempElements;
  };

  const verifyMultiple = (isAll) => {
    if (isAll) {
      let tempElements = getPendingFields();
      setIsAllSelected(true);
      setSelectedFields(tempElements);
    } else {
      setIsAllSelected(false);
    }
    setVerificationModalOpen(true);
  };

  const onAutoCompleteVerification = async () => {
    setLoading(true);
    const res = await verifyElement(
      details?.participant_uuid,
      {
        cached_questions_json: {
          question: cloneDeep(queJson),
        },
        is_completed: true,
      },
      token
    );
    if (res?.success) {
      toast.success(res.message);
      await fetchDetails();
    } else {
      toast.error(res.message);
    }
    setAutoCompleteModalOpen(false);
    setLoading(false);
  };

  const handleVerifyRejectDL = async () => {
    setDlLoading(true);
    const res = await manualVerification(
      { participant_uuid: details?.participant_uuid, manual_status: statusData },
      token
    );

    if (res?.success) {
      toast.success(res?.message);
      setDlConfirmationModalOpen(false);
      await getDL();
    } else {
      toast.error(res.message);
    }
    setDlLoading(false);
  };
  return (
    <Box className='dashboard-main'>
      <CommonModal
        open={dlConfirmationModalOpen}
        setOpen={setDlConfirmationModalOpen}
        title={'Confirmation'}
        description={
          statusData === 'verified'
            ? `Are you sure you want to verify government-issued ID? Please ensure that all details in the government-issued ID have been carefully reviewed.`
            : 'Are you sure you want to reject government-issued ID?'
        }
        handleSave={handleVerifyRejectDL}
      />
      {(loading || dlLoading || elementFetchLoading) && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <Grid item md={12} className='d-flex justify-between align-center sub-header'>
              <Typography variant='h4' className='mb-0'>
                Participant Form Details (
                {details?.user_participant_type_name ? details?.user_participant_type_name : ''})
              </Typography>
              <Tooltip title={!isCompleted ? 'Participant has not completed form yet.' : ''}>
                {selectedFields?.length ? (
                  <Button
                    variant='outlined'
                    disabled={!isCompleted}
                    onClick={() => {
                      verifyMultiple(false);
                    }}
                    className='mr-8'
                  >
                    {`Verify ${selectedFields?.length + ' Fields'}`}
                  </Button>
                ) : (
                  ''
                )}
                {!bankAccountClient && details?.data_verify_status !== 'completed' ? (
                  <Button
                    variant='contained'
                    disabled={!isCompleted}
                    onClick={() => {
                      verifyMultiple(true);
                    }}
                  >
                    {`Complete Verification`}
                  </Button>
                ) : (
                  ''
                )}
              </Tooltip>
              {details?.data_verify_status == 'completed' ? (
                <Chip
                  label={`Verification Status : ${details?.data_verify_status}`}
                  style={{
                    backgroundColor: '#2e7d32',
                  }}
                  className='mui-tag p-8 f-16'
                />
              ) : (
                ''
              )}
            </Grid>
            <Box>
              <CardContent>
                {!bankAccountClient && !isCompleted ? (
                  <Alert severity='warning' className='f-13'>
                    You can not Verify or Reject form fields as the participant has not completed
                    the form yet.
                  </Alert>
                ) : (
                  ''
                )}
                <Grid container spacing={2} className='client-user-form sub-header'>
                  {!orderDetails?.is_bank_account_client ? (
                    <Grid xs={12} className='question-section-container'>
                      <div className='section-title-label'>{'Attached Documents with form.'}</div>
                      <Grid container className='form-field-container mt-12'>
                        <DocumentLink
                          handleClick={() => statementPdf()}
                          label={`${
                            details?.user_participant_type_name ?? ''
                          } Statement Of Information`}
                        />
                        {/* {details?.user_participant_type_name === 'Seller' ? (
                        <DocumentLink
                          handleClick={() => {
                            downloadAdditionalDocs('core_lender_last_deed_1');
                          }}
                          label={`Last Recorded Deed`}
                        />
                      ) : (
                        ''
                      )} */}
                        {additionalDocs?.length ? (
                          additionalDocs.map((doc) => {
                            return (
                              // <Grid
                              //   key={doc?.name}
                              //   className={`file-preview media-link`}
                              //   style={{ padding: '4px 10px' }}
                              // >
                              //   <div className='d-flex flex-column'>
                              //     <div className='mb-4 file-label'>{doc?.label}</div>
                              //     <div className='d-flex flex-row'>
                              //       <div className='color-p'>
                              //         <Article fontSize='small' />
                              //       </div>
                              //       <div
                              //         className='color-p fw-500 f-13 ml-4'
                              //         onClick={() => {
                              //           downloadAdditionalDocs(doc?.name, doc?.label);
                              //         }}
                              //         style={{
                              //           cursor: 'pointer',
                              //           color: '005fd4',
                              //         }}
                              //       >
                              //         <div>{doc?.label}</div>
                              //       </div>
                              //     </div>
                              //   </div>
                              // </Grid>
                              <DocumentLink
                                handleClick={() =>
                                  downloadAdditionalDocs(doc?.name, doc?.participant_number)
                                }
                                label={doc?.label}
                              />
                            );
                          })
                        ) : (
                          <></>
                        )}

                        {/* <Grid
                        key={'statement'}
                        className={`file-preview media-link`}
                        style={{ padding: '4px 10px' }}
                      >
                        <div className='d-flex flex-column'>
                          <div className='mb-4 file-label'>{`${
                            details?.user_participant_type_name ?? ''
                          } Statement Of Information`}</div>
                          <div className='d-flex flex-row'>
                            <div className='color-p'>
                              <Article fontSize='small' />
                            </div>
                            <div
                              className='color-p fw-500 f-13 ml-4'
                              onClick={() => {
                                statementPdf();
                              }}
                              style={{
                                cursor: 'pointer',
                                color: '005fd4',
                              }}
                            >
                              <div>{`${
                                details?.user_participant_type_name ?? ''
                              } Statement Of Information`}</div>
                            </div>
                          </div>
                        </div>
                      </Grid> */}
                        {docs?.length ? (
                          docs.map((doc) => {
                            return (
                              // <Grid
                              //   key={doc?.participantId}
                              //   className={`file-preview`}
                              //   style={{ padding: '4px 10px' }}
                              // >
                              //   <div className='d-flex flex-column'>
                              //     <div className='mb-4 file-label'>
                              //       {doc?.participant_form_element_name}
                              //     </div>
                              //     <div className='d-flex flex-row'>
                              //       <div className='color-p'>
                              //         <Article fontSize='small' />
                              //       </div>
                              //       <div
                              //         className='color-p fw-500 f-13 ml-4'
                              //         onClick={() => {
                              //           getExtraDoc(doc?.url);
                              //         }}
                              //         style={{
                              //           cursor: 'pointer',
                              //           color: '005fd4',
                              //         }}
                              //       >
                              //         <div>{doc?.filename}</div>
                              //       </div>
                              //     </div>
                              //   </div>
                              // </Grid>
                              <DocumentLink
                                label={doc?.doc_name || 'Document'}
                                handleClick={() => getExtraDoc(doc?.url)}
                              />
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {Object.keys(dlDetails?.transaction_response || {})?.length && !isEntity ? (
                    <Grid xs={12} className='question-section-container'>
                      <div className='section-title-label'>
                        {dlDetails?.transaction_response?.IdentificationTypeId &&
                        ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                          ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                          : 'Government Issued ID'}{' '}
                        <span
                          style={{
                            color:
                              dlDetails?.manual_verification_status === 'verified'
                                ? 'green'
                                : 'red',
                          }}
                        >
                          {dlDetails?.manual_verification_status?.length
                            ? `(Manually ${
                                dlDetails?.manual_verification_status === 'verified'
                                  ? 'Verified'
                                  : 'Rejected'
                              })`
                            : ''}
                        </span>
                        {dlDetails?.transaction_response?.Approved ? (
                          <span className='color-s'>
                            (Verified
                            <VerifiedUser style={{ fontSize: '14px', marginBottom: '-2px' }} />)
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      {(dlDetails?.transaction_response?.NotApproved ||
                        !dlDetails?.transaction_response?.Approved) &&
                      !dlDetails?.manual_verification_status?.length ? (
                        <Alert
                          severity='error'
                          className='f-13 mt-8 fw-450 w-100p'
                          style={{ padding: '2px 10px' }}
                        >
                          {`Participant's government-issued ID has not been verified electronically. Please check and manually verify the government-issued ID.`}
                        </Alert>
                      ) : (
                        ''
                      )}
                      <ImageList cols={3} gap={20} variant='quilted'>
                        {dlDetails?.dl_image_1?.length ? (
                          <ImageListItem key={dlDetails?.dl_image_1}>
                            <img
                              src={`${dlDetails?.dl_image_1}`}
                              alt='gov-id-front'
                              className='driving-license'
                            />
                            <ImageListItemBar
                              title={`${
                                dlDetails?.transaction_response?.IdentificationTypeId &&
                                ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                                  ? ID_MAPPING?.[
                                      dlDetails?.transaction_response?.IdentificationTypeId
                                    ]
                                  : 'Government Issued ID'
                              } ${dlDetails?.dl_image_2?.length ? '[Front]' : ''}`}
                              // subtitle={
                              //   `${
                              //     dlDetails?.transaction_response?.FirstName
                              //       ? dlDetails?.transaction_response?.FirstName
                              //       : ''
                              //   }` +
                              //   ' ' +
                              //   `${
                              //     dlDetails?.transaction_response?.FirstSurname
                              //       ? dlDetails?.transaction_response?.FirstSurname
                              //       : ''
                              //   }`
                              // }
                            />
                          </ImageListItem>
                        ) : (
                          ''
                        )}
                        {dlDetails?.dl_image_2?.length ? (
                          <ImageListItem key={dlDetails?.dl_image_2}>
                            <img
                              src={`${dlDetails?.dl_image_2}`}
                              alt='gov-id-back'
                              className='driving-license'
                            />
                            <ImageListItemBar
                              title={`${
                                dlDetails?.transaction_response?.IdentificationTypeId &&
                                ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                                  ? ID_MAPPING?.[
                                      dlDetails?.transaction_response?.IdentificationTypeId
                                    ]
                                  : 'Government Issued ID'
                              } [Back]`}
                              // subtitle={
                              //   `${
                              //     dlDetails?.transaction_response?.FirstName
                              //       ? dlDetails?.transaction_response?.FirstName
                              //       : ''
                              //   }` +
                              //   ' ' +
                              //   `${
                              //     dlDetails?.transaction_response?.FirstSurname
                              //       ? dlDetails?.transaction_response?.FirstSurname
                              //       : ''
                              //   }`
                              // }
                            />
                          </ImageListItem>
                        ) : (
                          ''
                        )}
                      </ImageList>
                      {(dlDetails?.transaction_response?.NotApproved ||
                        !dlDetails?.transaction_response?.Approved) &&
                      !dlDetails?.manual_verification_status?.length ? (
                        <div className='d-flex justify-end'>
                          {' '}
                          <Button
                            autoFocus
                            color='primary'
                            variant='contained'
                            style={{ padding: '4px 8px' }}
                            onClick={() => {
                              setDlConfirmationModalOpen(true);
                              setStatusData('verified');
                            }}
                            disabled={!isCompleted}
                          >
                            Verify
                          </Button>
                          <Button
                            autoFocus
                            color='error'
                            variant='contained'
                            className='ml-8'
                            style={{ padding: '4px 8px' }}
                            onClick={() => {
                              setDlConfirmationModalOpen(true);
                              setStatusData('unverified');
                            }}
                            disabled={!isCompleted}
                          >
                            Reject
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </Grid>
                  ) : (
                    ''
                  )}
                  {Object.keys(allElements)?.map((section) => {
                    return (
                      <Grid xs={12} className='question-section-container'>
                        <div className='section-title-label'>{section}</div>
                        <Grid container spacing={2} className='form-field-container' rowGap={1}>
                          {allElements?.[section]?.map((element, index) => {
                            return (
                              <Grid item xs={12} sm={6} md={4} className='verification-field'>
                                <label style={{ color: 'grey' }}>{element?.label}</label>
                                {element?.required && <label className='required'>{'*'}</label>}
                                <div className='d-flex'>
                                  <ValidationTextField
                                    onClick={(e) => {
                                      onSelect(element);
                                    }}
                                    style={{
                                      caretColor: 'transparent',
                                      cursor: `${
                                        element?.is_verified_with_source ||
                                        element?.verified_with_source_by_user_id
                                          ? 'not-allowed'
                                          : 'pointer'
                                      }`,
                                      padding: `${
                                        !selectedFields
                                          .map((field) => field.element_id)
                                          .includes(element.element_id)
                                          ? 'unset'
                                          : '4px'
                                      }`,
                                      border: `${
                                        !selectedFields
                                          .map((field) => field.element_id)
                                          .includes(element.element_id)
                                          ? 'none'
                                          : '2px solid black'
                                      }`,
                                    }}
                                    // value={
                                    //   fullSSNs?.[element?.element_name]
                                    //     ? fullSSNs?.[element?.element_name]
                                    //     : element?.user_value
                                    // }
                                    value={
                                      SSN_FIELDS?.includes(element?.element_name)
                                        ? fullSSNs?.[element?.element_name]
                                          ? fullSSNs?.[element?.element_name]
                                          : element?.ssn_ein_masked_number
                                        : element?.user_value
                                    }
                                    name={element?.label}
                                    variant='outlined'
                                    fullWidth
                                    readonly
                                    focused={false}
                                    disabled={
                                      element?.is_verified_with_source ||
                                      element?.verified_with_source_by_user_id ||
                                      !isCompleted ||
                                      details?.data_verify_status == 'completed'
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          <Tooltip
                                            title={
                                              element?.is_verified_with_source ||
                                              element?.verified_with_source_by_user_id
                                                ? 'View verification details.'
                                                : 'Verify field.'
                                            }
                                          >
                                            <IconButton
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setCurData(element);
                                                setOpen(true);
                                              }}
                                            >
                                              {element?.is_verified_with_source ||
                                              element?.verified_with_source_by_user_id ? (
                                                <CheckCircleOutline
                                                  fontSize='small'
                                                  style={{ color: 'green', cursor: 'pointer' }}
                                                />
                                              ) : (
                                                <ErrorOutline
                                                  fontSize='small'
                                                  style={{ color: 'orange', cursor: 'pointer' }}
                                                />
                                              )}
                                            </IconButton>
                                          </Tooltip>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  {SSN_FIELDS?.includes(element?.element_name) &&
                                  !fullSSNs?.[element?.element_name] ? (
                                    <Tooltip title={'Show full SSN'}>
                                      <Button
                                        onClick={() => {
                                          getFullSSN(element);
                                        }}
                                        style={{ minWidth: '35px' }}
                                        disabled={!isCompleted}
                                      >
                                        <Visibility fontSize='small' style={{ color: 'black' }} />
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                {element?.tx_error ? (
                                  <div
                                    className='color-w f-12'
                                    dangerouslySetInnerHTML={{ __html: element?.tx_error }}
                                  ></div>
                                ) : (
                                  ''
                                )}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
      {/* <SourceDetailsModal
        open={open}
        setOpen={setOpen}
        curData={curData}
        onVerify={onVerify}
        onUnverify={onUnverify}
      /> */}
      <SourceDetailsDrawer
        disabled={!isCompleted || details?.data_verify_status == 'completed'}
        open={open}
        setOpen={setOpen}
        curData={curData}
        onVerify={onVerify}
        onUnverify={onUnverify}
      />
      <VerificationConfirmationModal
        open={verificationModalOpen}
        setOpen={setVerificationModalOpen}
        selectedFields={selectedFields}
        setSelectedFields={setSelectedFields}
        onVerify={onVerify}
        isAll={isAllSelected}
      />
      <DocumentViewer
        open={docModalOpen}
        setOpen={setDocModalOpen}
        fileUrl={url}
        title={'Document'}
        originalUrl={originalUrl}
      />
      <AutoCompleteModal
        open={autoCompleteModalOpen}
        setOpen={setAutoCompleteModalOpen}
        onVerify={onAutoCompleteVerification}
      />
    </Box>
  );
};
export default ViewFormDetails;
