import React, { useEffect,  useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MenuItem, Select, Grid,  FormControl } from '@mui/material';
import DocumentField from '../index.jsx';
import {
  BANK_CITY_NAME,
  BANK_NAME_NAME,
  BANK_STATE_NAME,
  DL_STATE_FIELD,
  DocElementType,
  FIELDS_TO_DISABLE,
} from '../../../../utils/constants';
import useRedux from '../../../../utils/hooks/useRedux';
import {
  areAllElementsSame,
  errorChecker,
  fetchSourceData,
} from '../../../../utils/helpers.js';
import ValueSelectionModal from '../../../questions/home/ValueSelectionModal.jsx';
import { useDispatch } from 'react-redux';
import Loader from '../../Loader.jsx';
const States = {};
const menuItemStyle = {
  display: 'flex',
  textAlign: 'left',
  justifyContent: 'flex-start',
  paddingLeft: 20,
  fontSize: 14,
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'unset',
};

const SelectBox = ({ element, orderId, disabled, saveFormDataPHH }) => {
  const { code } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const isDisableForm = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.is_pdf_generated;
  const dlDetails = useSelector((state) => state.questionSlice.dlDetails)?.[code];

  // const question = useSelector((state) => state.questionSlice.questionDetails)?.find(
  //   (que) => que.tx_id === code
  // )?.questions;
  const { setFormValue, getFormValue, getErrorDetails, removeError, setError, setTouched } =
    useRedux();
  const formValue = getFormValue(element.element_id);
  const { error, error_message, error_type } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const [value, setValue] = useState(0);
  const [sourceList, setSourceList] = useState([]);
  const [open, setOpen] = useState(false);
  const [disabledField, setDisabledField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dataVal =
    formValue !== undefined ? formValue : element.user_value ? element.user_value : '';

  const hiddenFields = [BANK_NAME_NAME, BANK_CITY_NAME, BANK_STATE_NAME];

  useEffect(() => {
    setSourceList(fetchSourceData(element));
  }, [element]);

  // const disabledBankFields = useMemo(() => {
  //   let disabledFields = [];
  //   if (bankDetails) {
  //     for (const bankType in bankDetails) {
  //       if (bankDetails?.[bankType]?.associated_fields?.length) {
  //         disabledFields = [...disabledFields, ...bankDetails?.[bankType]?.associated_fields];
  //       }
  //     }
  //   }
  //   return disabledFields;
  // }, [bankDetails]);

  useEffect(() => {
    if (
      (sourceList?.length === 1 || areAllElementsSame(sourceList.map((source) => source?.value))) &&
      !element.user_value
    ) {
      if (element?.options?.map((opt) => opt.option_name)?.includes(sourceList[0]?.value)) {
        setValue(sourceList[0]?.value);
        setFormValue({ id: element.element_id, value: sourceList[0]?.value });
        // setDisabledField(true);
      }
    }
  }, [sourceList]);

  useEffect(() => {
    if (formValue !== undefined) setValue(formValue);
    else if (element.user_value) setValue(element.user_value);
  }, [formValue]);

  // for populating DL State
  useEffect(() => {
    if (
      element.element_id === DL_STATE_FIELD &&
      dlDetails?.state &&
      dlDetails?.transaction_response?.Approved
    ) {
      if (!dataVal) {
        setFormValue({
          id: element.element_id,
          value: dlDetails?.state,
        });
        setValue(dlDetails?.state);
        removeError(element.element_id);
        setTouched(element.element_id);
      }
    }
  }, [element?.element_id, dlDetails, dataVal]);

  const handleError = async (value) => {
    setIsLoading(true);
    const { isError, message } = errorChecker(element, value);
    if (!isError) {
      removeError(element.element_id);
    } else {
      setError({ id: element.element_id, error: true, error_message: message });
    }
    // to handle error for mailing address using moby's response
    // if ([MAILING_ADDRESS_STATE].includes(element.element_id)) {
    //   const userAddr = getUserAddress();
    //   if (Object.keys(userAddr || {})?.length && dlDetails?.transaction_response?.CustomerAddress) {
    //     const addressResult = await matchAddress(
    //       dlDetails?.transaction_response?.CustomerAddress,
    //       { ...userAddr, site_state: value },
    //       authData?.token
    //     );
    //     let data = {
    //       ...dlDetails,
    //       errors: addressResult,
    //     };
    //     dispatch(addDlDetails({ tx_id: code, data: { ...data } }));
    //   }
    // }
    // if (
    //   element.element_id === DL_STATE_FIELD &&
    //   dlDetails?.state &&
    //   value &&
    //   value !== dlDetails?.state
    // ) {
    //   setError({
    //     id: element.element_id,
    //     error: true,
    //     error_message:
    //       'The value you entered does not match the information on your uploaded government issued ID. Please correct it.',
    //     type: 'warning',
    //   });
    // }
    setIsLoading(false);
  };

  const onSelect = (value) => {
    setTouched(element.element_id);
    setValue(value);
    setFormValue({ id: element.element_id, value: value });
    handleError(value);
    setOpen(false);
  };

  if (
    element.element_id === DL_STATE_FIELD &&
    dlDetails?.transaction_response?.Approved &&
    dlDetails?.transaction_response?.IdentificationTypeId &&
    dlDetails?.transaction_response?.IdentificationTypeId !== 22
  ) {
    return;
  }

  if (hiddenFields?.includes(element.label?.replace(':', '')) && !element.value_from_bank_source) {
    return;
  }

  const isDisabledField =
    FIELDS_TO_DISABLE?.includes(element.label.replace(':', '')) &&
    element.value_from_bank_source &&
    element.value_from_bank_source !== 'no';
  return (
    <>
      {isLoading && <Loader />}

      <Grid xs={12} md={6} spacing={1} className='grid-container plt-8'>
        <Grid xs={12} md={12} item={true}>
          <label
            dangerouslySetInnerHTML={{
              __html: languageForLabel
                ? translations?.[languageForLabel]?.elements?.[element.element_id]
                  ? translations?.[languageForLabel]?.elements?.[element.element_id]
                  : element?.label
                : element?.label,
            }}
          />
          <label className='required'>{element.required && '*'}</label>
        </Grid>
        <Grid xs={12} md={12} item={true} className='mt-4 t-field'>
          <div className='d-flex align-items-center'>
            <FormControl
              error={error && error_type !== 'warning'}
              className='full-width question-select-field'
            >
              <Select
                className='full-width question-select-field'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={value || ''}
                renderValue={(value) => (
                  <span>
                    {' '}
                    {languageForLabel
                      ? translations?.[languageForLabel]?.options?.[
                          element.options?.find((elem) => elem.option_name === value)?.option_id
                        ]
                        ? translations?.[languageForLabel]?.options?.[
                            element.options?.find((elem) => elem.option_name === value)?.option_id
                          ]
                        : value
                      : value}
                  </span>
                )}
                onBlur={() => {
                  handleError(value);
                }}
                disabled={disabled || disabledField || isDisabledField}
              >
                <Grid container>
                  <Grid item md={12} style={{ borderRight: '1px solid #e1dcdc' }}>
                    {element.element_type === DocElementType.SSelectBox
                      ? Object.keys(States).map((key, idx) => {
                          return (
                            <MenuItem
                              value={key}
                              key={idx}
                              style={menuItemStyle}
                              onClick={() => {
                                onSelect(key);
                              }}
                              className={value === key ? 'bg-selected' : ''}
                            >
                              {States[key]}
                            </MenuItem>
                          );
                        })
                      : element.options.map((option, idx) => {
                          return (
                            <MenuItem
                              value={option.option_name}
                              key={idx}
                              style={menuItemStyle}
                              onClick={() => {
                                onSelect(option.option_name);
                              }}
                              className={value === option.option_name ? 'bg-selected' : ''}
                            >
                              {languageForLabel
                                ? translations?.[languageForLabel]?.options?.[option.option_id]
                                  ? translations?.[languageForLabel]?.options?.[option.option_id]
                                  : option?.option_name
                                : option?.option_name}
                            </MenuItem>
                          );
                        })}
                  </Grid>
                  {/* <Grid item md={5.5}>
                    <div
                      className='d-flex justify-center f-14 fw-700 pb-16 pt-8'
                      style={{ borderBottom: '1px solid #e1dcdc' }}
                    >
                      Suggested Options
                    </div>
                    <div
                      className={sourceList?.length ? '' : 'd-flex justify-center'}
                      style={{ minHeight: '35px' }}
                    >
                      {sourceList?.length ? (
                        sourceList.map((source, index) => {
                          return (
                            <MenuItem
                              value={source?.value}
                              key={index}
                              style={menuItemStyle}
                              onClick={() => {
                                onSelect(source?.value);
                              }}
                            >
                              {source?.value}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <span className='not-available-text mt-4'>Not available</span>
                      )}
                    </div>
                  </Grid> */}
                </Grid>
              </Select>
              {/* <FormHelperText>{error_message}</FormHelperText> */}
            </FormControl>
            {/* {sourceList?.length > 1 &&
            !areAllElementsSame(sourceList.map((source) => source?.value)) ? (
              <Tooltip title='Click to Select value from given list'>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Tune style={{ color: 'grey', marginLeft: '2px' }} fontSize='small' />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )} */}
          </div>
        </Grid>
        {/* {dlDetails?.errors?.[element.element_id] ? (
          <div className='color-w f-12'>
            The value you entered does not match the information on your uploaded driver’s license.
            Please correct it.
          </div>
        ) : (
          ''
        )} */}
      </Grid>
      {/* {dlFields.includes(element.element_id) ? (
        dlDetails?.status === 'approved' ? (
          <>
            <Grid xs={24} md={24} item={true} className='mt-4'>
              <label className='f-14'>
                Uploaded Driving License{' '}
                {isVerified ? (
                  <span className='color-s'>
                    (Verified <VerifiedUser style={{ fontSize: '14px', marginBottom: '-2px' }} />)
                  </span>
                ) : (
                  <span className='error'>
                    (Pending <ErrorOutline style={{ fontSize: '14px', marginBottom: '-2px' }} />)
                  </span>
                )}
              </label>
            </Grid>
            {!isVerified && (
              <Alert
                severity='error'
                className='f-13 mt-8 fw-450 w-100p'
                style={{ padding: '2px 10px' }}
              >
                Please ensure that the First Name and Last Name match the details on your uploaded
                driver's license.
              </Alert>
            )}
            <Grid xs={24} md={24} item={true} className='mt-4 mb-4'>
              <ImageList cols={2} gap={20} variant='quilted'>
                <ImageListItem key={dlDetails?.dl_image_1}>
                  <img
                    src={`${dlDetails?.dl_image_1}`}
                    alt='driving-license-front'
                    className='driving-license'
                  />
                  <ImageListItemBar
                    title={'Driving License Front'}
                    subtitle={
                      dlDetails?.transaction_response?.FirstName +
                      ' ' +
                      dlDetails?.transaction_response?.FirstSurname
                    }
                  />
                </ImageListItem>
                <ImageListItem key={dlDetails?.dl_image_2}>
                  <img
                    src={`${dlDetails?.dl_image_2}`}
                    alt='driving-license-front'
                    className='driving-license'
                  />
                  <ImageListItemBar
                    title={'Driving License Back'}
                    subtitle={
                      dlDetails?.transaction_response?.FirstName +
                      ' ' +
                      dlDetails?.transaction_response?.FirstSurname
                    }
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
          </>
        ) : (
          <>
            {Object.keys(dlDetails?.transaction_response || {})?.length &&
            (dlDetails?.transaction_response?.NotApproved ||
              !dlDetails?.transaction_response?.Approved) ? (
              <Grid xs={24} md={24} item={true} className='mt-4'>
                <Alert
                  severity='error'
                  className='f-13 mt-8 fw-450 w-100p'
                  style={{ padding: '2px 10px' }}
                >
                  {dlDetails?.transaction_response?.ErrorMessage
                    ? dlDetails?.transaction_response?.ErrorMessage
                    : 'Your driving license has not been approved. Please try uploading it again using the QR code or link below.'}
                </Alert>
              </Grid>
            ) : (
              ''
            )}
            <Grid xs={24} md={24} item={true} className='mt-4'>
              <label className=''>
                Please scan the QR code below or click the provided link to verify your driving
                license. You will only be allowed to proceed further upon successful verification.
              </label>
            </Grid>
            <Grid xs={24} md={24} item={true} className='mt-4'>
              <div className='d-flex justify-center'>
                <img
                  style={{ width: '250px', height: 'auto' }}
                  src={qrDetails?.qrcode_url ?? ''}
                  alt='qr-code'
                />
              </div>
            </Grid>
            <Grid xs={24} md={24} item={true} className='mt-4'>
              <Divider>OR</Divider>
            </Grid>
            <Grid xs={24} md={24} item={true} className='mt-4'>
              <div
                onClick={() => window.open(qrDetails?.invitation_url ?? '', '_blank')}
                className='f-13 fw-500 color-p cursor-pointer'
                style={{ wordBreak: 'break-word' }}
              >
                {qrDetails?.invitation_url ?? ''}
              </div>
            </Grid>
          </>
        )
      ) : (
        ''
      )} */}
      {element.options &&
        element.options.map(
          (option, idx) =>
            option.option_name == value && (
              <React.Fragment key={idx}>
                {option.elements.map((element, index) => {
                  return (
                    <DocumentField
                      key={index}
                      element={element}
                      orderId={orderId}
                      disabled={isDisableForm}
                      saveFormDataPHH={saveFormDataPHH}
                    />
                  );
                })}
              </React.Fragment>
            )
        )}
      <ValueSelectionModal
        setOpen={setOpen}
        open={open}
        data={sourceList}
        onSelect={onSelect}
        value={value}
      />
    </>
  );
};

export default SelectBox;
