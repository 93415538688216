import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  CardContent,
  Box,
  Typography,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  InputAdornment,
  Alert,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  getOrder,
  getOrderLabel,
  updateOrder,
  addParticipants,
  getOrderTypes,
  updateParticipant,
  fetchLinkStatus,
  getSSN,
} from '../../../services/order/order';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import Loader from '../../../components/common/Loader';
import {
  Cached,
  InfoOutlined,
  Link,
  CalendarToday,
  Visibility,
  ExpandMore,
  Delete,
  ArrowForwardIos,
  ArrowBackIosNew,
  KeyboardTabRounded,
  VerifiedUser,
  ErrorOutline,
} from '@mui/icons-material';
import { resendLink } from '../../../services/admin/participantForms';
import {
  ADMIN_ROLE,
  ENTITY_TYPES,
  OFFICE_USER_ROLE,
  CLIENT_USER_ROLE,
  STATES,
  SUPER_ADMIN_ROLE,
} from '../../../utils/constants';
import { getParticipantType } from '../../../services/admin/participantType';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { cloneDeep } from 'lodash';
import { getDocumentDetails } from '../../../services/questions/home';
import DocumentViewer from '../../../components/common/DocumentViewer';
import DatePicker from 'react-datepicker';
import { formatDateToYYYYMMDD, randomUUID } from '../../../utils/helpers';
import ParticipantStatusStepper from '../../../components/common/ParticipantStatusStepper';

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: '#ecf3f9a3',
  // flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    fontSize: '13px',
    fontWeight: '500',
  },
}));

const OrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { order } = useSelector((state) => state?.authSlice);
  const isSuperAdmin = order?.user_role_name === SUPER_ADMIN_ROLE;
  const bankAccountClient = order?.is_bank_account_client;
  const isClientUser =
    order?.user_role_name === OFFICE_USER_ROLE || order?.user_role_name === CLIENT_USER_ROLE
      ? true
      : false;
  const isAdmin =
    order?.user_role_name === ADMIN_ROLE || order?.user_role_name === SUPER_ADMIN_ROLE
      ? true
      : false;

  const hostname = window.location.hostname;
  const port = window.location.port;
  const domainName = port ? `${hostname}:${port}` : hostname;
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);
  const [isIndividual, setIsIndividual] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [participantAddress, setParticipantAddress] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [linkStatus, setLinkStatus] = useState({});
  const [emailChecked, setEmailChecked] = useState(false);
  const [showEmailReminder, setShowEmailReminder] = useState(false);
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [url, setUrl] = useState('');
  const [originalUrl, setOriginalUrl] = useState('');
  const [fullSSN, setFullSSN] = useState({});
  const { handleSubmit, control, reset, setValue, getValues, trigger, formState } = useForm();
  const isDisable = isAdmin || orderDetail?.order_status_name === 'Completed';
  const isImport = window.location.pathname.includes('imported-orders');

  const generateStatusList = (participant) => {
    let additionalParticipants = cloneDeep(participant?.additional_participants) || [];
    additionalParticipants.sort((a, b) => {
      if (a.form_status === 'completed' && b.form_status === 'pending') return -1;
      if (a.form_status === 'pending' && b.form_status === 'completed') return 1;
      return a.participant_number - b.participant_number;
    });
    let status = [
      {
        name: `Primary ${participant.participant_type_name} ${participant?.form_status}`,
        message: `The form filling for Primary ${participant.participant_type_name} is ${participant?.form_status}`,
        isDone: participant?.form_status === 'completed',
        isCurrent: participant?.form_status === 'pending',
      },
    ];
    let isPendingFound = participant?.form_status === 'pending';
    let isCompleted = participant?.form_status === 'completed';
    additionalParticipants?.forEach((part) => {
      status.push({
        name: part?.is_spouse
          ? `Spouse ${part?.form_status}`
          : `Additional ${participant.participant_type_name} ${part?.participant_number} ${part?.form_status}`,
        message: part?.is_spouse
          ? `The form filling for spouse is ${part?.form_status}`
          : `The form filling for Additional ${participant.participant_type_name} ${
              part?.participant_number
            }(${part?.first_name + ' ' + part?.last_name}) is ${part?.form_status}`,
        isDone: part?.form_status === 'completed',
        isCurrent: !isPendingFound && part?.form_status === 'pending',
      });
      if (part?.form_status === 'pending') isPendingFound = true;
      isCompleted = part?.form_status === 'completed';
    });
    status.push({
      name: `Completed`,
      message: `${participant.participant_type_name}(s) have completed filling out the form.`,
      isDone: false,
      isCurrent: isCompleted,
    });
    return status;
  };

  const fetchOrderLabel = async (companyId) => {
    setLoading(true);
    const res = await getOrderLabel(companyId, order?.token);
    setLoading(false);
  };

  const editOrderDetail = async (data) => {
    setLoading(true);
    try {
      const res = await updateOrder(
        id,
        {
          ...data,
          anticipated_close_date: data?.anticipated_close_date
            ? formatDateToYYYYMMDD(data?.anticipated_close_date)
            : '',
          escrow_close_date: data?.escrow_close_date
            ? formatDateToYYYYMMDD(data?.escrow_close_date)
            : '',
          is_send_reminder_email: emailChecked ? 'yes' : 'no',
          client_id: order?.client_id,
        },
        order?.token
      );
      if (res?.success) {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
      fetchOrderDetail();
    } catch {
      toast.error('something went wrong');
    }
    setLoading(false);
  };

  const generateParticipantTypeOptions = (idx) => {
    let types = [...participantList];
    if (orderDetail?.participants?.length) {
      const tempParticipantIds = orderDetail?.participants?.map(
        (participant) => participant.participant_type_id
      );
      types = types?.filter((type) => !tempParticipantIds.includes(type?.participant_type_id));
    }
    let participantIds = getValues('participants')?.map(
      (participant) => participant.user_participant_type_id
    );
    participantIds = participantIds?.filter((_, index) => index !== idx);
    types = types?.filter((type) => !participantIds?.includes(type?.participant_type_id));
    return types;
  };
  const fetchParticipantList = async () => {
    setLoading(true);
    const res = await getParticipantType(
      order?.token,
      order?.client_id,
      orderDetail?.client_office_id
    );
    if (res?.success) {
      let participantTypes = res?.response;
      if (orderDetail?.order_type_id && orderTypes?.length) {
        let tempParticipantTypes = orderTypes
          ?.find((o) => o.id == orderDetail?.order_type_id)
          ?.participant_types?.map((o) => o?.participant_type_name);
        const tempTypes = res?.response?.filter((type) =>
          tempParticipantTypes?.includes(type.participant_type_name)
        );
        participantTypes = tempTypes;
      }

      if (orderDetail?.participants?.length) {
        // const sellerIdx = orderDetail?.participants?.findIndex(
        //   (participant) => participant?.user_role_name?.toLowerCase() === 'seller'
        // );
        // if (sellerIdx != -1) {
        //   participantTypes = participantTypes.filter(
        //     (type) => type?.participant_type_name?.toLowerCase() !== 'seller'
        //   );
        // }

        const participantIds = orderDetail?.participants?.map(
          (participant) => participant.participant_type_id
        );
        participantTypes = participantTypes?.filter(
          (type) => !participantIds.includes(type?.participant_type_id)
        );
      }

      setParticipantList(participantTypes);
    }
    setLoading(false);
  };
  const fetchOrderTypes = async () => {
    setLoading(true);
    let clientId = order?.client_id;
    let clientOfficeId = orderDetail?.client_office_id;
    if (!clientId) clientId = orderDetail?.client_office?.client_id;
    if (!clientOfficeId) clientOfficeId = orderDetail?.client_office?.client_office_id;
    const res = await getOrderTypes(order?.token, clientId, clientOfficeId);
    if (res?.success) {
      setOrderTypes(res?.response || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrderTypes();
  }, [orderDetail?.client_office_id]);

  useEffect(() => {
    fetchParticipantList();
  }, [orderDetail?.order_type_id, orderDetail?.client_office_id, orderTypes]);

  // link status

  const getLinkStatus = async (participants) => {
    setLoading(true);
    let tempLinkStatus = cloneDeep(linkStatus);
    for (const participant of participants) {
      const res = await fetchLinkStatus(participant.uuid, id, order?.token);
      if (res?.success) tempLinkStatus[participant.uuid] = res?.response?.is_source_data_pulled;
    }
    setLinkStatus(tempLinkStatus);
    setLoading(false);
  };

  const getFullSSN = async (participant_id) => {
    setLoading(true);
    const res = await getSSN(
      { order_participant_id: participant_id, show_number: true },
      order?.token
    );
    if (res.success) {
      setFullSSN((prev) => {
        return { ...prev, [participant_id]: res?.response?.full_number };
      });
    } else {
      toast.error('Something went wrong! Please try again.');
    }
    setLoading(false);
  };

  // Need to refactor entire default field setting logic & order detail logic

  const fetchOrderDetail = async () => {
    setLoading(true);
    reset();
    const res = await getOrder(id, order?.token);

    if (res?.success) {
      if (!res?.response?.participants?.length) {
        toast.error('Something went wrong!');
        navigate(-1);
      }
      setNewParticipants(
        res?.response?.participants.map((participant) => {
          let tempPart = participant;
          if (tempPart?.is_individual_or_entity) {
            tempPart.is_individual = participant?.is_individual_or_entity === 'individual';
          } else {
            tempPart.is_individual = participant?.business_name?.length ? false : true;
          }
          return tempPart;
        })
      );
      setOrderDetail(res?.response || []);
      setShowEmailReminder(res?.response?.is_send_reminder_email_client == 'yes' ? true : false);
      setEmailChecked(res?.response?.is_send_reminder_email == 'yes' ? true : false);
      await getLinkStatus(res?.response?.participants);
      if (res?.response?.company_id) {
        fetchOrderLabel(res?.response?.company_id);
      }
      const fieldsToSet = [
        'property_address_1',
        'property_city',
        'property_state',
        'property_zipcode',
        'property_county',
        'property_unit_no',
        'loan_reference_number',
        'borrower_name',
        'seller_name',
        'order_type_id',
      ];

      fieldsToSet.forEach((fieldName) => {
        setValue(fieldName, res?.response?.[fieldName]);
      });
      let tempParticipantAddress = [];
      for (const [idx, existingPart] of Object.entries(res?.response?.participants)) {
        tempParticipantAddress.push(existingPart?.['address_line_1']);
        setValue(`participants[${idx}].first_name`, existingPart?.['first_name']);
        setValue(`participants[${idx}].last_name`, existingPart?.['last_name']);
        setValue(`participants[${idx}].email`, existingPart?.['email']);
        setValue(`participants[${idx}].ssn_number`, existingPart?.['ssn_number']);
        setValue(
          `participants[${idx}].user_participant_type_id`,
          existingPart?.['participant_type_name']
        );
        setValue(`participants[${idx}].mobile_phone`, existingPart?.['mobile_phone']);
        setValue(`participants[${idx}].address_line_1`, existingPart?.['address_line_1']);
        setValue(`participants[${idx}].address_line_2`, existingPart?.['address_line_2']);
        setValue(`participants[${idx}].city`, existingPart?.['city']);
        setValue(`participants[${idx}].state`, existingPart?.['state']);
        setValue(`participants[${idx}].zip_code`, existingPart?.['zip_code']);
        setValue(`participants[${idx}].county`, existingPart?.['county']);
        setValue(`participants[${idx}].unit_no`, existingPart?.['unit_no']);
        setValue(`participants[${idx}].business_name`, existingPart?.['business_name']);
        setValue(`participants[${idx}].entity_type`, existingPart?.['entity_type']);
        setValue(`participants[${idx}].entity_state`, existingPart?.['entity_state']);
        setValue(`participants[${idx}].entity_ein`, existingPart?.['entity_ein']);
      }
      setParticipantAddress(tempParticipantAddress);
      // setValue('tags', res?.response?.tags?.map(item => item.id) || []);
      setValue('escrow_number', res?.response?.['escrow_num']);
      setValue('client_name', res?.response?.['client_office']?.['office_name']);
      setValue('client_contact', res?.response?.['client_office']?.['contact_name']);
      setValue('client_email', res?.response?.['client_office']?.['contact_email']);
      setValue('client_phone', res?.response?.['client_office']?.['phone_no']);
      setValue('order_date', new Date(res?.response?.['created_at']));
      setValue('anticipated_close_date', new Date(res?.response?.['anticipated_close_date']));
      setValue(
        'escrow_close_date',
        res?.response?.['escrow_close_at'] ? new Date(res?.response?.['escrow_close_at']) : null
      );
    }
    setLoading(false);
  };
  // Need to refactor entire default field setting logic

  const setDefaultFields = () => {
    const fieldsToSet = [
      'order_type_id',
      'loan_reference_number',
      'property_address_1',
      'property_city',
      'property_state',
      'property_zipcode',
      'property_county',
      'property_unit_no',
    ];
    const participantFieldToSet = [
      'first_name',
      'last_name',
      'email',
      'ssn_number',
      'mobile_phone',
      'address_line_1',
      'address_line_2',
      'city',
      'state',
      'zip_code',
      'county',
      'unit_no',
      'business_name',
      'entity_type',
      'entity_state',
      'entity_ein',
    ];
    setValue('client_name', orderDetail?.['client_office']?.['office_name']);
    setValue('client_contact', orderDetail?.['client_office']?.['contact_name']);
    setValue('client_email', orderDetail?.['client_office']?.['contact_email']);
    setValue('client_phone', orderDetail?.['client_office']?.['phone_no']);
    setValue('order_date', new Date(orderDetail?.['created_at']));
    setValue('anticipated_close_date', new Date(orderDetail?.['anticipated_close_date']));
    setValue('escrow_number', orderDetail?.['escrow_num']);
    setValue(
      'escrow_close_date',
      orderDetail?.['escrow_close_at'] ? new Date(orderDetail?.['escrow_close_at']) : null
    );
    fieldsToSet.forEach((fieldName) => {
      setValue(fieldName, orderDetail?.[fieldName]);
    });
    for (const [idx, existingPart] of Object.entries(orderDetail?.participants)) {
      participantFieldToSet.forEach((fieldName) => {
        setValue(`participants[${idx}].${fieldName}`, existingPart?.[fieldName]);
      });
      setValue(
        `participants[${idx}].user_participant_type_id`,
        existingPart?.['participant_type_name']
      );
    }
  };

  useEffect(() => {
    if (orderDetail?.id) setDefaultFields();
  }, [tabValue]);

  useEffect(() => {
    if (id) fetchOrderDetail();
  }, [id]);

  useEffect(() => {
    if (id) fetchOrderDetail();
  }, [id]);

  const onSubmit = (data) => {
    // Handle form submission here
    delete data.order_date;
    if (data?.escrow_close_date === 'Invalid date') data.escrow_close_date = '';
    editOrderDetail(data);
  };

  const resendInviteLink = async (participant_uuid, order_participant_id) => {
    setLoading(true);
    const res = await resendLink({ participant_uuid, order_participant_id }, order?.token);
    if (res?.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const handleAddParticipant = () => {
    setNewParticipants((parts) => [
      ...parts,
      { is_new: true, id: randomUUID(), is_individual: true },
    ]);
    setParticipantAddress([...participantAddress, '']);
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight + 500);
    }, 1000);
  };

  const removeParticipant = (index) => {
    let tempParticipantValues = getValues('participants');
    tempParticipantValues.splice(index, 1);
    setValue('participants', tempParticipantValues);
    const updatedParticipants = [...newParticipants];
    updatedParticipants.splice(index, 1);
    setNewParticipants(updatedParticipants);

    setParticipantAddress((prevData) => {
      return prevData.filter((_, i) => i !== index);
    });
  };

  const onParticipantsChange = async () => {
    await trigger('participants');
    if (Object.keys(formState.errors || {})?.length) return;
    setLoading(true);
    const values = getValues();
    let tempParticipants = cloneDeep(values.participants);
    let tempAllParticipants = cloneDeep(newParticipants);
    tempAllParticipants.splice(0, orderDetail?.participants.length);
    tempParticipants.splice(0, orderDetail?.participants?.length);
    let bodyData = {};
    bodyData.participants = tempParticipants;
    bodyData.participants = bodyData.participants.map((participant, index) => {
      return {
        ...participant,
        is_address_same_as_order_property: 'no',
        is_individual_or_entity: tempAllParticipants?.[index]?.is_individual
          ? 'individual'
          : 'entity',
      };
    });
    bodyData.order_id = orderDetail?.id;
    bodyData.property_address_type = 'address';
    bodyData.is_participant_or_entity = isIndividual ? 'entity' : 'participant';
    const res = await addParticipants(bodyData, order?.token);
    if (res.success) {
      toast.success(res.message);
      await fetchOrderDetail();
      await fetchParticipantList();
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const updateParticipantData = async (participantId, index) => {
    trigger();
    setLoading(true);
    const formData = getValues();
    let body = {
      first_name: formData?.participants?.[index]?.first_name,
      last_name: formData?.participants?.[index]?.last_name,
      email: formData?.participants?.[index]?.email,
    };
    for (const key of Object.keys(body)) {
      if (
        orderDetail?.participants?.[index]?.is_individual_or_entity === 'entity' &&
        key !== 'email'
      )
        continue;
      if (!body[key]?.length) {
        return setLoading(false);
      }
    }
    body.mobile_phone = formData?.participants?.[index]?.mobile_phone;
    body.order_id = +id;
    const res = await updateParticipant(participantId, body, order?.token);
    if (res?.success) {
      toast.success(res.message);
      await fetchOrderDetail();
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const handleParticipantSelect = async (value, index) => {
    try {
      const results = await geocodeByAddress(value);
      const addressComponents = results[0].address_components;
      const streetAddress = addressComponents
        .filter((comp) => comp.types.includes('street_number') || comp.types.includes('route'))
        .map((comp) => comp.short_name)
        .join(' ');
      setParticipantAddress((prevData) => {
        return prevData.map((item, i) => (i === index ? streetAddress : item));
      });
      let countyArr =
        addressComponents
          .find((comp) => comp.types.includes('administrative_area_level_2'))
          ?.long_name?.split(' ') || [];
      countyArr.pop();
      setValue(`participants[${index}].address_line_1`, streetAddress);
      setValue(
        `participants[${index}].address_line_2`,
        addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || ''
      );
      setValue(
        `participants[${index}].city`,
        addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || ''
      );
      setValue(
        `participants[${index}].state`,
        addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))
          ?.short_name || ''
      );
      setValue(
        `participants[${index}].zip_code`,
        addressComponents.find((comp) => comp.types.includes('postal_code'))?.long_name || ''
      );
      setValue(`participants[${index}].county`, countyArr.join(' ') || '');
      trigger();
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const downloadAdditionalDocs = async (elemName, uuid) => {
    setLoading(true);
    const res = await getDocumentDetails(
      {
        order_id: id,
        form_element_name: elemName,
      },
      order?.token
    );
    if (res?.success && res?.response?.files?.[0]?.url) {
      fetch(res?.response?.files?.[0]?.url)
        .then((response) => {
          if (!response.ok) {
            setLoading(false);
            toast.error('Document is not available');
          }
          return response.blob();
        })
        .then((blobData) => {
          setLoading(false);
          const blobUrl = URL.createObjectURL(blobData);
          setUrl(blobUrl);
          setOriginalUrl(res?.response?.files?.[0]?.url);
          setDocModalOpen(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error('Document is not available');
    }
  };

  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <DocumentViewer
          open={docModalOpen}
          setOpen={setDocModalOpen}
          fileUrl={url}
          title={'Document'}
          originalUrl={originalUrl}
        />

        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={collapsed ? 2.5 : 4} className='order-info-sec'>
                  <div className='order-form'>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className='client-user-form form-field-container'
                    >
                      <Grid container spacing={1} style={{ marginTop: '12px' }}>
                        <Grid container spacing={1} item xs={12} sm={12}>
                          <Grid item xs={12}>
                            <div className='form-title mb-8'>
                              <h4 style={{ marginTop: '-15px' }} className='f-16'>
                                Order Information
                              </h4>
                            </div>
                          </Grid>
                          {/* <Grid item xs={12} sm={4}>
                            <label>Client Office Name</label>
                            <Controller
                              name='client_name'
                              control={control}
                              disabled={true}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <label>Client Office Contact</label>
                            <Controller
                              name='client_contact'
                              disabled={true}
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <label>Client Office Email</label>
                            <Controller
                              name='client_email'
                              disabled={true}
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <label>Client Office Phone</label>
                            <Controller
                              name='client_phone'
                              disabled={true}
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid> */}
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Order Type</label>
                            <Controller
                              disabled={true}
                              name={`order_type_id`}
                              control={control}
                              defaultValue=''
                              render={({ field, fieldState }) => (
                                <FormControl fullWidth>
                                  <Select {...field}>
                                    {orderTypes?.map((item) => {
                                      return <MenuItem value={item?.id}>{item?.name}</MenuItem>;
                                    })}
                                    {orderTypes?.length === 0 && (
                                      <MenuItem value=''>Order Type Not Found</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Order Date</label>
                            <Controller
                              name='order_date'
                              control={control}
                              defaultValue=''
                              disabled={true}
                              render={({ field }) => (
                                <DatePicker
                                  showIcon
                                  selected={getValues('order_date')}
                                  autoComplete='off'
                                  disabled={true}
                                  customInput={
                                    <TextField
                                      className='full-width w-100p'
                                      variant='outlined'
                                      autoComplete='off'
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <CalendarToday
                                              fontSize='small'
                                              style={{ color: 'grey' }}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  }
                                />
                              )}
                            />
                            {/* <Controller
                                name='order_date'
                                control={control}
                                defaultValue={null}
                                disabled={true}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type='date'
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    variant='outlined'
                                  />
                                )}
                              /> */}
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Anticipated Close Date</label>
                            <Controller
                              name='anticipated_close_date'
                              control={control}
                              defaultValue={null}
                              disabled={isDisable}
                              render={({ field }) => (
                                <DatePicker
                                  showIcon
                                  utcOffset={0}
                                  selected={getValues('anticipated_close_date')}
                                  autoComplete='off'
                                  minDate={new Date()}
                                  disabled={isDisable}
                                  onChange={(date) => {
                                    setValue('anticipated_close_date', date);
                                  }}
                                  customInput={
                                    <TextField
                                      className='full-width w-100p'
                                      variant='outlined'
                                      autoComplete='off'
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <CalendarToday
                                              fontSize='small'
                                              style={{ color: 'grey' }}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  }
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Escrow Number</label>
                            <Controller
                              name='escrow_number'
                              control={control}
                              defaultValue=''
                              disabled={isDisable}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  variant='outlined'
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Escrow Close</label>
                            <Controller
                              name='escrow_close_date'
                              control={control}
                              defaultValue={null}
                              disabled={isDisable}
                              render={({ field }) => (
                                <DatePicker
                                  showIcon
                                  utcOffset={0}
                                  selected={getValues('escrow_close_date')}
                                  autoComplete='off'
                                  minDate={new Date()}
                                  disabled={isDisable}
                                  onChange={(date) => {
                                    setValue('escrow_close_date', date);
                                  }}
                                  customInput={
                                    <TextField
                                      className='full-width w-100p'
                                      variant='outlined'
                                      autoComplete='off'
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <CalendarToday
                                              fontSize='small'
                                              style={{ color: 'grey' }}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  }
                                />
                              )}
                            />
                          </Grid>
                          {showEmailReminder ? (
                            <Grid item xs={12} className='mt-16 checkbox-container'>
                              <FormControlLabel
                                style={{ minWidth: 'unset' }}
                                className='mt-8 order-form-checkbox'
                                label={`Email reminders will be sent to participants based on client
                                configurations if this box is checked. Contact your administrator to
                                update the frequency of the reminders.`}
                                checked={emailChecked}
                                onChange={(e) => {
                                  setEmailChecked(e.target.checked);
                                }}
                                disabled={isDisable}
                                control={<Checkbox color='primary' />}
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          {/* <Grid item xs={12} sm={4}>
                              <label>Last Recorded Deed</label>
                              <Controller
                                name='loan_reference_number'
                                control={control}
                                defaultValue=''
                                disabled={isAdmin}
                                InputLabelProps={{ shrink: true }}
                                render={({ field }) => (
                                  <TextField {...field} fullWidth variant='outlined' />
                                )}
                              />
                            </Grid> */}
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={12}>
                          <Grid item xs={12}>
                            <div className='form-title color-p mt-16'>
                              <h4 className='f-16'>Property Address</h4>
                            </div>
                          </Grid>

                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Street Address</label>
                            <Controller
                              name='property_address_1'
                              control={control}
                              defaultValue=''
                              disabled={isDisable}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  // label='Street Address'
                                  variant='outlined'
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Address 2</label>
                            <Controller
                              name='property_address_2'
                              control={control}
                              disabled={isDisable}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Unit Number</label>
                            <Controller
                              name='property_unit_no'
                              control={control}
                              disabled={isDisable}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>City</label>
                            <Controller
                              name='property_city'
                              control={control}
                              disabled={isDisable}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>State</label>
                            <Controller
                              name='property_state'
                              control={control}
                              defaultValue=''
                              disabled={isDisable}
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>Zip Code</label>
                            <Controller
                              name='property_zipcode'
                              control={control}
                              disabled={isDisable}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={collapsed ? 12 : 6}>
                            <label>County</label>
                            <Controller
                              name='property_county'
                              control={control}
                              disabled={isDisable}
                              defaultValue=''
                              render={({ field }) => (
                                <TextField {...field} fullWidth variant='outlined' />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        className='d-flex justify-end footer-submit-btn'
                      >
                        <Tooltip
                          title={
                            isDisable
                              ? 'You cannot update order details as the order has already been completed.'
                              : null
                          }
                        >
                          <div>
                            {!isAdmin ? (
                              <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={isDisable}
                                endIcon={loading && <CircularProgress size={20} />}
                                style={{ marginTop: '60px' }}
                              >
                                UPDATE
                              </Button>
                            ) : (
                              ''
                            )}{' '}
                          </div>
                        </Tooltip>
                      </Grid>
                    </form>
                  </div>
                </Grid>
                <Grid item md={0.5} className='order-form-divider'>
                  <div class='expand-collapse-container'>
                    <div class='vertical-line'></div>
                    <div
                      className='icon-button cursor-pointer'
                      style={{ background: 'white' }}
                      onClick={() => setCollapsed((col) => !col)}
                    >
                      {collapsed ? (
                        <ArrowForwardIos className='shrink-icon' />
                      ) : (
                        <ArrowBackIosNew className='shrink-icon' />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item md={collapsed ? 9 : 7.5}>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {' '}
                      <div className='f-16 fw-600 color-p mt-10 d-flex align-items-center'>
                        Participants
                      </div>
                      <div className='mt-16 d-flex flex-end-imp'>
                        {!isAdmin ? (
                          <Button
                            type='button'
                            onClick={handleAddParticipant}
                            className='add-participant-btn mb-20'
                            variant='outlined'
                            color='primary'
                            style={{ height: '35px' }}
                          >
                            Add Participant
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div
                      className='client-user-form order-form disabled-form-setup'
                      style={{ minHeight: '55vh' }}
                    >
                      {newParticipants.map((participant, index) => (
                        <Accordion defaultExpanded={index === 0 || participant?.is_new}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            {participant?.participant_type_name ? (
                              <div className='fw-600 f-13 acc-title'>
                                {participant?.participant_type_name}
                              </div>
                            ) : (
                              ''
                            )}
                          </AccordionSummary>
                          <AccordionDetails>
                            {' '}
                            <Grid
                              item
                              xs={12}
                              key={participant?.order_part_participant_id}
                              className='mt-12 p-8'
                            >
                              <Grid container spacing={1}>
                                {participant?.is_new ? (
                                  <Grid
                                    item
                                    xs={12}
                                    className='remove-participant-btn d-flex justify-end'
                                  >
                                    <Tooltip title='Remove Participant'>
                                      <Button
                                        style={{ position: 'absolute' }}
                                        type='button'
                                        onClick={() => removeParticipant(index)}
                                      >
                                        <Delete className='error f-18' />
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                {!orderDetail?.is_bank_account_client && !participant?.is_new ? (
                                  <Grid item xs={12} className='mb-4'>
                                    {' '}
                                    <div
                                      className='d-flex justify-between'
                                      // style={{ width: '1000px' }}
                                    >
                                      <div
                                        className='d-flex flex-row p-0'
                                        style={{ height: 'fit-content' }}
                                      >
                                        <div
                                          className='f-13 fw-500 d-flex flex-column status-parent'
                                          style={{
                                            padding: '10px 20px 12px 10px',
                                            width: 'fit-content',
                                          }}
                                          // borderRight={'0px'}
                                        >
                                          <div className='mb-4 color-p status-child fw-550'>
                                            Form Status{' '}
                                          </div>{' '}
                                          <div className='mt-6'>
                                            <ParticipantStatusStepper
                                              statusList={generateStatusList(participant)}
                                            />
                                          </div>
                                          {/* <span
                                            className='text-capitalize ml-4'
                                            style={{
                                              color:
                                                participant?.status === 'completed'
                                                  ? 'green'
                                                  : '#005fd4',
                                            }}
                                          >
                                            {participant?.status}
                                          </span>{' '}
                                          <Tooltip
                                            title={`${
                                              participant?.status === 'completed'
                                                ? 'The participant has successfully filled out the form.'
                                                : 'The participant has yet to finalize and complete the form.'
                                            }`}
                                          >
                                            <InfoOutlined
                                              className='f-14 ml-4 mt-4'
                                              style={{
                                                color:
                                                  participant?.status === 'completed'
                                                    ? 'green'
                                                    : '#005fd4',
                                              }}
                                            />
                                          </Tooltip> */}
                                        </div>

                                        {/* <div
                                          className='f-13 fw-500 p-8 d-flex flex-column ml-8 status-parent'
                                          style={{
                                            width: '200px',
                                          }}
                                        >
                                         
                                          <span
                                            className='text-capitalize ml-4'
                                            style={{
                                              color:
                                                participant?.status === 'completed'
                                                  ? 'green'
                                                  : '#005fd4',
                                            }}
                                          >
                                            {participant?.data_verify_status}
                                          </span>{' '}
                                          <Tooltip
                                            title={`${
                                              participant?.data_verify_status === 'completed'
                                                ? `The participant form is verified on ${participant?.data_verify_status_updated_at}.`
                                                : 'The participant form verification is pending.'
                                            }`}
                                          >
                                            <InfoOutlined
                                              className='f-14 ml-4 mt-4'
                                              style={{
                                                color:
                                                  participant?.status === 'completed'
                                                    ? 'green'
                                                    : '#005fd4',
                                              }}
                                            />
                                          </Tooltip>
                                        </div> */}
                                      </div>

                                      {/* <Chip
                                          onDelete={() => {}}
                                          label={`Form status : ${participant?.status}`}
                                          deleteIcon={
                                            <Tooltip
                                              title={`${
                                                participant?.status === 'completed'
                                                  ? 'The participant has successfully filled out the form.'
                                                  : 'The participant has yet to finalize and complete the form.'
                                              }`}
                                            >
                                              <InfoOutlined
                                                style={{
                                                  color: 'white',
                                                  fontSize: '14px',
                                                  marginLeft: '-4px',
                                                }}
                                              />
                                            </Tooltip>
                                          }
                                          style={{
                                            backgroundColor: `${
                                              participant?.status === 'completed'
                                                ? '#2e7d32'
                                                : '#1976d2'
                                            }`,
                                            height: '25px',
                                            borderRadius: '5px',
                                            padding: '4px',
                                          }}
                                        />
                                        <Chip
                                          onDelete={() => {}}
                                          label={`Verification Status : ${participant?.data_verify_status}`}
                                          className='ml-4'
                                          deleteIcon={
                                            <Tooltip
                                              title={`${
                                                participant?.data_verify_status === 'completed'
                                                  ? `The participant form is verified on ${participant?.data_verify_status_updated_at}.`
                                                  : 'The participant form verification is pending.'
                                              }`}
                                            >
                                              <InfoOutlined
                                                style={{
                                                  color: 'white',
                                                  fontSize: '14px',
                                                  marginLeft: '-4px',
                                                }}
                                              />
                                            </Tooltip>
                                          }
                                          style={{
                                            backgroundColor: `${
                                              participant?.data_verify_status === 'completed'
                                                ? '#2e7d32'
                                                : '#1976d2'
                                            }`,
                                            height: '25px',
                                            padding: '4px',
                                            borderRadius: '5px',
                                          }}
                                        /> */}

                                      {/* <IconButton onClick={() => {}}>
                                        <KeyboardTab
                                          color='primary'
                                          className='cursor-pointer'
                                          onClick={() =>
                                            navigate(
                                              `/${isClientUser ? 'orders' : 'admin/orders'}/${id}/${
                                                participant.id
                                              }`
                                            )
                                          }
                                        />
                                      </IconButton> */}
                                      {/* <Button
                                    className='f-13'
                                    onClick={() =>
                                      navigate(
                                        `/${isClientUser ? 'orders' : 'admin/orders'}/${id}/${
                                          participant.id
                                        }`
                                      )
                                    }
                                  >
                                    Form Details & Documents
                                    <KeyboardTab style={{ fontSize: '20px', marginLeft: '8px' }} />
                                  </Button> */}
                                    </div>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                <Grid xs={12} className='order-detail-des pl-8'>
                                  <div className='d-flex align-items-center mt-8 mb-8 toggle-label'>
                                    <span className='mr-12 f-14 fw-500 '>Individual</span>
                                    <FormControlLabel
                                      control={
                                        <Controller
                                          name='is_participant_or_entity'
                                          control={control}
                                          render={({}) => (
                                            <Switch
                                              disabled={!participant.is_new}
                                              checked={!participant?.is_individual}
                                              onChange={() => {
                                                setNewParticipants((oldParticipants) => {
                                                  let tempParticipants = [...oldParticipants];
                                                  tempParticipants[index].is_individual =
                                                    !tempParticipants[index].is_individual;
                                                  return tempParticipants;
                                                });

                                                setValue(`participants[${index}].first_name`, '');
                                                setValue(`participants[${index}].last_name`, '');
                                                setValue(
                                                  `participants[${index}].business_name`,
                                                  ''
                                                );
                                                setValue(`participants[${index}].email`, '');
                                              }}
                                              color='primary'
                                              size='small'
                                            />
                                          )}
                                        />
                                      }
                                      style={{
                                        minWidth: 'unset',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                      }}
                                      className='pt-8'
                                      label='Entity'
                                    />
                                  </div>
                                </Grid>
                                {!participant.is_new &&
                                orderDetail?.loan_reference_number &&
                                participant?.participant_type_name === 'Seller' ? (
                                  <>
                                    <Grid item xs={12} sm={4}>
                                      <label>Last Recorded Deed</label>
                                      <Controller
                                        name='loan_reference_number'
                                        control={control}
                                        defaultValue=''
                                        disabled={!participant.is_new}
                                        InputLabelProps={{ shrink: true }}
                                        render={({ field }) => (
                                          <TextField {...field} fullWidth variant='outlined' />
                                        )}
                                      />
                                    </Grid>{' '}
                                    <Grid item xs={12} sm={8}>
                                      <div
                                        className='mt-23 ml-8 d-flex align-items-center'
                                        style={{
                                          minWidth: 'unset',
                                          color: '#005fd4',
                                          textDecoration: 'underline',
                                          cursor: 'pointer',
                                          fontSize: '14px',
                                          height: '125%',
                                          width: 'fit-content',
                                        }}
                                        onClick={() => {
                                          downloadAdditionalDocs(
                                            'core_lender_last_deed_1',
                                            participant?.uuid
                                          );
                                        }}
                                      >
                                        Last Recorded Deed Link
                                      </div>
                                    </Grid>
                                  </>
                                ) : (
                                  ''
                                )}
                                {participant.is_individual ? (
                                  <>
                                    <Grid item sm={4} xs={12}>
                                      <label>Participant First Name</label>
                                      <label className='required'>{'*'}</label>
                                      <Controller
                                        name={`participants[${index}].first_name`}
                                        control={control}
                                        defaultValue=''
                                        rules={{ required: 'First Name is required' }}
                                        disabled={participant?.data_verify_status === 'completed'}
                                        render={({ field, fieldState }) => (
                                          <>
                                            <TextField
                                              {...field}
                                              onChange={(e) => {
                                                field.onChange(e); // Propagate the change event
                                                trigger(`participants[${index}].first_name`); // Trigger revalidation
                                              }}
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                              fullWidth
                                            />
                                          </>
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Participant Last Name</label>
                                      <label className='required'>{'*'}</label>
                                      <Controller
                                        name={`participants[${index}].last_name`}
                                        control={control}
                                        rules={{ required: 'Last Name is required' }}
                                        defaultValue=''
                                        disabled={participant?.data_verify_status === 'completed'}
                                        render={({ field, fieldState }) => (
                                          <TextField
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e); // Propagate the change event
                                              trigger(`participants[${index}].last_name`); // Trigger revalidation
                                            }}
                                            fullWidth
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Participant Type</label>
                                      <label className='required'>{'*'}</label>
                                      {participant?.is_new ? (
                                        <Controller
                                          name={`participants[${index}].user_participant_type_id`}
                                          control={control}
                                          rules={{ required: 'Role is required' }}
                                          defaultValue=''
                                          disabled={!participant.is_new}
                                          render={({ field, fieldState }) => (
                                            <FormControl fullWidth>
                                              <Select
                                                {...field}
                                                onChange={(e) => {
                                                  field.onChange(e); // Propagate the change event
                                                  trigger(
                                                    `participants[${index}].user_participant_type_id`
                                                  ); // Trigger revalidation
                                                }}
                                              >
                                                {generateParticipantTypeOptions(index).map(
                                                  (item) => {
                                                    return (
                                                      <MenuItem value={item?.participant_type_id}>
                                                        {item?.participant_type_name}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                                {generateParticipantTypeOptions(index)?.length ===
                                                  0 && (
                                                  <MenuItem value=''>
                                                    You have already added maximum participants..
                                                  </MenuItem>
                                                )}
                                              </Select>
                                              <Typography
                                                className='order-error f-12'
                                                color='error'
                                              >
                                                {fieldState.error ? fieldState.error.message : ''}
                                              </Typography>
                                            </FormControl>
                                          )}
                                        />
                                      ) : (
                                        <TextField
                                          disabled
                                          id='filled-basic'
                                          variant='outlined'
                                          readonly={true}
                                          value={participant?.participant_type_name}
                                          fullWidth
                                        />
                                      )}
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Social Security Number</label>
                                      {participant?.is_new ? (
                                        <Controller
                                          name={`participants[${index}].ssn_number`}
                                          control={control}
                                          disabled={!participant.is_new}
                                          defaultValue=''
                                          render={({ field: { ref, ...field }, fieldState }) => (
                                            <InputMask mask='999-99-9999' {...field}>
                                              {() => (
                                                <TextField
                                                  {...field}
                                                  fullWidth
                                                  error={!!fieldState.error}
                                                  helperText={
                                                    fieldState.error ? fieldState.error.message : ''
                                                  }
                                                />
                                              )}
                                            </InputMask>
                                          )}
                                        />
                                      ) : (
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                          <TextField
                                            disabled
                                            id='filled-basic'
                                            variant='outlined'
                                            readonly={true}
                                            // value='xxx-xx-7777'
                                            value={
                                              fullSSN?.[participant?.participant_id]
                                                ? fullSSN?.[participant?.participant_id]
                                                : participant?.ssn_ein_number
                                            }
                                            fullWidth
                                          />
                                          {participant?.ssn_ein_number?.length &&
                                          !fullSSN?.[participant?.participant_id] ? (
                                            <Tooltip title={'Show full SSN'}>
                                              <Button
                                                onClick={() => {
                                                  getFullSSN(participant?.participant_id);
                                                }}
                                                style={{ minWidth: '35px' }}
                                              >
                                                {/* Get Full SSN */}
                                                <Visibility
                                                  fontSize='small'
                                                  style={{ color: 'black' }}
                                                />
                                              </Button>
                                            </Tooltip>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )}
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Email</label>
                                      <label className='required'>{'*'}</label>
                                      <Controller
                                        name={`participants[${index}].email`}
                                        control={control}
                                        rules={{
                                          required: 'Email is required',
                                          pattern: {
                                            value: /^\S+@\S+\.\S+$/,
                                            message: 'Invalid email format',
                                          },
                                        }}
                                        defaultValue=''
                                        disabled={participant?.data_verify_status === 'completed'}
                                        render={({ field, fieldState }) => (
                                          <TextField
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e); // Propagate the change event
                                              trigger(`participants[${index}].email`); // Trigger revalidation
                                            }}
                                            fullWidth
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Cell Phone</label>
                                      <Controller
                                        name={`participants[${index}].mobile_phone`}
                                        control={control}
                                        defaultValue=''
                                        disabled={participant?.data_verify_status === 'completed'}
                                        render={({ field: { ref, ...field }, fieldState }) => (
                                          <InputMask mask={'(999) 999-9999'} {...field}>
                                            {() => (
                                              <TextField
                                                {...field}
                                                fullWidth
                                                error={!!fieldState.error}
                                                helperText={
                                                  fieldState.error ? fieldState.error.message : ''
                                                }
                                              />
                                            )}
                                          </InputMask>
                                        )}
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item sm={4} xs={12}>
                                      <label>Legal Business Name</label>
                                      <label className='required'>{'*'}</label>
                                      <Controller
                                        name={`participants[${index}].business_name`}
                                        control={control}
                                        disabled={!participant.is_new}
                                        rules={{
                                          required: participant.is_new
                                            ? 'Business Name is required'
                                            : false,
                                        }}
                                        defaultValue=''
                                        render={({ field, fieldState }) => (
                                          <TextField
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e); // Propagate the change event
                                              trigger(`participants[${index}].business_name`); // Trigger revalidation
                                            }}
                                            fullWidth
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Entity Type</label>
                                      <Controller
                                        name={`participants[${index}].entity_type`}
                                        rules={{ required: false }}
                                        control={control}
                                        disabled={!participant.is_new}
                                        defaultValue=''
                                        render={({ field, fieldState }) => (
                                          <FormControl fullWidth>
                                            <Select {...field}>
                                              {ENTITY_TYPES.map((ent) => (
                                                <MenuItem value={ent}>{ent}</MenuItem>
                                              ))}
                                            </Select>
                                            <Typography className='order-error' color='error'>
                                              {fieldState.error ? fieldState.error.message : ''}
                                            </Typography>
                                          </FormControl>
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Entity State</label>
                                      <Controller
                                        name={`participants[${index}].entity_state`}
                                        rules={{ required: false }}
                                        disabled={!participant.is_new}
                                        control={control}
                                        defaultValue=''
                                        render={({ field, fieldState }) => (
                                          <FormControl fullWidth>
                                            <Select {...field}>
                                              {STATES.map((state) => (
                                                <MenuItem value={state}>{state}</MenuItem>
                                              ))}
                                            </Select>
                                            <Typography className='order-error' color='error'>
                                              {fieldState.error ? fieldState.error.message : ''}
                                            </Typography>
                                          </FormControl>
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Participant Type</label>
                                      <label className='required'>{'*'}</label>
                                      {participant?.is_new ? (
                                        <Controller
                                          name={`participants[${index}].user_participant_type_id`}
                                          control={control}
                                          rules={{ required: 'Role is required' }}
                                          defaultValue=''
                                          disabled={!participant.is_new}
                                          render={({ field, fieldState }) => (
                                            <FormControl fullWidth>
                                              <Select
                                                {...field}
                                                onChange={(e) => {
                                                  field.onChange(e); // Propagate the change event
                                                  trigger(
                                                    `participants[${index}].user_participant_type_id`
                                                  ); // Trigger revalidation
                                                }}
                                              >
                                                {generateParticipantTypeOptions(index)?.map(
                                                  (item) => {
                                                    return (
                                                      <MenuItem value={item?.participant_type_id}>
                                                        {item?.participant_type_name}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                                {generateParticipantTypeOptions(index)?.length ===
                                                  0 && (
                                                  <MenuItem value=''>
                                                    Participant Type Not Found
                                                  </MenuItem>
                                                )}
                                              </Select>
                                              <Typography
                                                className='order-error f-12'
                                                color='error'
                                              >
                                                {fieldState.error ? fieldState.error.message : ''}
                                              </Typography>
                                            </FormControl>
                                          )}
                                        />
                                      ) : (
                                        <TextField
                                          disabled
                                          id='filled-basic'
                                          variant='outlined'
                                          readonly={true}
                                          value={participant?.participant_type_name}
                                          fullWidth
                                        />
                                      )}
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Entity EIN</label>
                                      {participant.is_new ? (
                                        <Controller
                                          name={`participants[${index}].entity_ein`}
                                          control={control}
                                          rules={{ required: false }}
                                          defaultValue=''
                                          disabled={!participant.is_new}
                                          render={({ field: { ref, ...field }, fieldState }) => (
                                            <InputMask mask={'99-9999999'} {...field}>
                                              {() => (
                                                <TextField
                                                  {...field}
                                                  fullWidth
                                                  error={!!fieldState.error}
                                                  helperText={
                                                    fieldState.error ? fieldState.error.message : ''
                                                  }
                                                />
                                              )}
                                            </InputMask>
                                          )}
                                        />
                                      ) : (
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                          <TextField
                                            disabled
                                            id='filled-basic'
                                            variant='outlined'
                                            readonly={true}
                                            // value='xxx-xx-7777'
                                            value={
                                              fullSSN?.[participant?.participant_id]
                                                ? fullSSN?.[participant?.participant_id]
                                                : participant?.ssn_ein_number
                                            }
                                            fullWidth
                                          />
                                          {participant?.ssn_ein_number?.length &&
                                          !fullSSN?.[participant?.participant_id] ? (
                                            <Tooltip title={'Show full EIN'}>
                                              <Button
                                                onClick={() => {
                                                  getFullSSN(participant?.participant_id);
                                                }}
                                                style={{ minWidth: '35px' }}
                                              >
                                                {/* Get Full SSN */}
                                                <Visibility
                                                  fontSize='small'
                                                  style={{ color: 'black' }}
                                                />
                                              </Button>
                                            </Tooltip>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )}
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Contact First Name</label>
                                      <Controller
                                        name={`participants[${index}].first_name`}
                                        control={control}
                                        rules={{ required: false }}
                                        disabled={participant?.data_verify_status === 'completed'}
                                        defaultValue=''
                                        render={({ field, fieldState }) => (
                                          <TextField
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e); // Propagate the change event
                                              trigger(`participants[${index}].first_name`); // Trigger revalidation
                                            }}
                                            fullWidth
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Contact Last Name</label>
                                      <Controller
                                        name={`participants[${index}].last_name`}
                                        rules={{ required: false }}
                                        disabled={participant?.data_verify_status === 'completed'}
                                        control={control}
                                        defaultValue=''
                                        render={({ field, fieldState }) => (
                                          <TextField
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e); // Propagate the change event
                                              trigger(`participants[${index}].last_name`); // Trigger revalidation
                                            }}
                                            fullWidth
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Email</label>
                                      <label className='required'>{'*'}</label>
                                      <Controller
                                        name={`participants[${index}].email`}
                                        rules={{ required: 'Email is required' }}
                                        control={control}
                                        disabled={participant?.data_verify_status === 'completed'}
                                        defaultValue=''
                                        render={({ field, fieldState }) => (
                                          <TextField
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e); // Propagate the change event
                                              trigger(`participants[${index}].email`); // Trigger revalidation
                                            }}
                                            fullWidth
                                            error={!!fieldState.error}
                                            helperText={
                                              fieldState.error ? fieldState.error.message : ''
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                      <label>Cell Phone</label>
                                      <Controller
                                        name={`participants[${index}].mobile_phone`}
                                        control={control}
                                        disabled={participant?.data_verify_status === 'completed'}
                                        defaultValue=''
                                        render={({ field: { ref, ...field }, fieldState }) => (
                                          <InputMask mask={'(999) 999-9999'} {...field}>
                                            {() => (
                                              <TextField
                                                {...field}
                                                fullWidth
                                                error={!!fieldState.error}
                                                helperText={
                                                  fieldState.error ? fieldState.error.message : ''
                                                }
                                              />
                                            )}
                                          </InputMask>
                                        )}
                                      />
                                    </Grid>
                                  </>
                                )}
                                <Grid item={12} container spacing={1} className='address-section'>
                                  <>
                                    <Grid container item sm={12} xs={12} spacing={1}>
                                      <Grid item xs={12} sm={4} className='relative'>
                                        <label>Street Address</label>
                                        <label className='required'>{'*'}</label>
                                        <PlacesAutocomplete
                                          value={participantAddress[index]}
                                          onChange={(newValue) => {
                                            const updatedParticipantAddress = [
                                              ...participantAddress,
                                            ];
                                            updatedParticipantAddress[index] = newValue;
                                            setParticipantAddress(updatedParticipantAddress);
                                            setValue(
                                              `participants[${index}].address_line_1`,
                                              newValue
                                            );
                                          }}
                                          onSelect={(e) => {
                                            handleParticipantSelect(e, index);
                                          }}
                                        >
                                          {({
                                            getInputProps,
                                            suggestions,
                                            getSuggestionItemProps,
                                            loading,
                                          }) => (
                                            <Controller
                                              name={`participants[${index}].address_line_1`}
                                              control={control}
                                              defaultValue=''
                                              rules={{
                                                required: participant.is_new
                                                  ? 'Address is required'
                                                  : false,
                                              }}
                                              disabled={!participant.is_new}
                                              render={({ field, fieldState }) => (
                                                <div>
                                                  <TextField
                                                    {...field}
                                                    {...getInputProps({
                                                      placeholder: 'Enter an address',
                                                    })}
                                                    className='address-field'
                                                    fullWidth
                                                    disabled={!participant.is_new}
                                                    variant='outlined'
                                                    name={`participants[${index}].address_line_1`}
                                                    helperText={
                                                      fieldState.error
                                                        ? fieldState.error.message
                                                        : ''
                                                    }
                                                  />
                                                  <div
                                                    // className={
                                                    //   suggestions?.length > 0 || loading
                                                    //     ? 'address-list'
                                                    //     : ''
                                                    // }
                                                    style={{
                                                      position: 'absolute',
                                                      boxShadow:
                                                        '0 4px 3px rgba(0,0,0,0.1), 0 4px 3px rgba(0,0,0,0.1)',
                                                      background: '#fff',
                                                      zIndex: '999',
                                                      // width: '28.5%',
                                                      maxHeight: '300px',
                                                      overflowY: 'auto',
                                                    }}
                                                  >
                                                    {suggestions.map((suggestion) => {
                                                      const style = {
                                                        backgroundColor: suggestion.active
                                                          ? '#ecf3f9'
                                                          : 'white',
                                                        padding: '10px 10px',
                                                        fontSize: '13.5px',
                                                      };
                                                      return (
                                                        <div
                                                          key={suggestion.description}
                                                          {...getSuggestionItemProps(suggestion, {
                                                            style,
                                                          })}
                                                        >
                                                          {suggestion.description}
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                              )}
                                            />
                                          )}
                                        </PlacesAutocomplete>
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <label>Address 2</label>
                                        <Controller
                                          name={`participants[${index}].address_line_2`}
                                          control={control}
                                          defaultValue=''
                                          rules={{ required: false }}
                                          disabled={!participant.is_new}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <label>Unit Number</label>
                                        <Controller
                                          name={`participants[${index}].unit_no`}
                                          disabled={!participant.is_new}
                                          control={control}
                                          defaultValue=''
                                          rules={{ required: false }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <label>City</label>
                                        <label className='required'>{'*'}</label>
                                        <Controller
                                          name={`participants[${index}].city`}
                                          control={control}
                                          defaultValue=''
                                          disabled={!participant.is_new}
                                          rules={{
                                            required: participant.is_new
                                              ? 'City is required'
                                              : false,
                                          }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <label>State</label>
                                        <label className='required'>{'*'}</label>
                                        <Controller
                                          name={`participants[${index}].state`}
                                          control={control}
                                          defaultValue=''
                                          disabled={!participant.is_new}
                                          rules={{
                                            required: participant.is_new
                                              ? 'State is required'
                                              : false,
                                          }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <label>ZIP Code</label>
                                        <label className='required'>{'*'}</label>
                                        <Controller
                                          name={`participants[${index}].zip_code`}
                                          control={control}
                                          defaultValue=''
                                          disabled={!participant.is_new}
                                          rules={{
                                            required: participant.is_new
                                              ? 'ZIP code is required'
                                              : false,
                                          }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              type='number'
                                              variant='outlined'
                                              fullWidth
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <label>County</label>
                                        <Controller
                                          name={`participants[${index}].county`}
                                          disabled={!participant.is_new}
                                          control={control}
                                          defaultValue=''
                                          rules={{
                                            required: false,
                                          }}
                                          render={({ field, fieldState }) => (
                                            <TextField
                                              {...field}
                                              variant='outlined'
                                              fullWidth
                                              error={!!fieldState.error}
                                              helperText={
                                                fieldState.error ? fieldState.error.message : ''
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </>
                                  {!participant?.is_new ? (
                                    <Grid item xs={12} sm={12} className='mt-8'>
                                      <div className='color-p f-14 fw-550'>
                                        Participant Invite Links
                                      </div>
                                      <Grid container className='mt-8'>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          className='d-flex align-items-center'
                                        >
                                          <div className='f-13-5 fw-500 mr-4'>
                                            Primary{' '}
                                            {participant?.participant_type_name
                                              ? participant?.participant_type_name
                                              : 'Participant'}{' '}
                                            (
                                            {participant?.is_individual_or_entity === 'entity'
                                              ? participant?.business_name
                                              : participant?.first_name +
                                                ' ' +
                                                participant?.last_name}
                                            ):
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={linkStatus[participant?.uuid] ? 10 : 12}
                                          sm={linkStatus[participant?.uuid] ? 10 : 12}
                                        >
                                          <div className='d-flex' style={{ flexDirection: 'row' }}>
                                            {' '}
                                            <div
                                              className='mt-4 f-13-5 fw-500 cursor-pointer'
                                              style={{
                                                pointerEvents: !linkStatus[participant?.uuid]
                                                  ? 'none'
                                                  : 'unset',
                                                color: !linkStatus[participant?.uuid]
                                                  ? 'grey'
                                                  : '#005fd4',
                                              }}
                                              onClick={(e) => {
                                                orderDetail?.domain
                                                  ? window.open(
                                                      `${orderDetail?.domain}/questions/invite/${participant?.uuid}`,
                                                      '_blank'
                                                    )
                                                  : window.open(
                                                      `https://${domainName}/questions/invite/${participant?.uuid}`,
                                                      '_blank'
                                                    );
                                              }}
                                            >
                                              {`${
                                                orderDetail?.domain
                                                  ? orderDetail?.domain
                                                  : domainName
                                              }/questions/invite/${participant?.uuid}`}
                                            </div>
                                            {!linkStatus[participant?.uuid] ? (
                                              <div>
                                                <Tooltip title='Click to get latest status of invite link.'>
                                                  <Button
                                                    variant='outlined'
                                                    style={{
                                                      minWidth: '0px',
                                                      color: 'orange',
                                                      border: 'none',
                                                    }}
                                                    onClick={() => {
                                                      getLinkStatus(orderDetail?.participants);
                                                    }}
                                                  >
                                                    <Cached fontSize='small' />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                          {' '}
                                          {linkStatus[participant?.uuid] ? (
                                            <div className='ml-12'>
                                              <Tooltip title='Click to copy participant invite link.'>
                                                <Button
                                                  variant='outlined'
                                                  color='secondary'
                                                  style={{
                                                    minWidth: 'unset',
                                                    width: '10px',
                                                    // color: 'grey',
                                                    // border: '1px solid #cbc9c9',
                                                  }}
                                                  className='mr-8'
                                                  onClick={() => {
                                                    copy(
                                                      `${
                                                        orderDetail?.domain
                                                          ? orderDetail?.domain
                                                          : domainName
                                                      }/questions/invite/${participant?.uuid}`
                                                    );
                                                    toast.success('Copied to clipboard!');
                                                  }}
                                                >
                                                  <ContentCopyIcon style={{ fontSize: '16px' }} />
                                                </Button>
                                              </Tooltip>
                                              <Tooltip title='Click to resend participant invite link.'>
                                                <Button
                                                  variant='outlined'
                                                  style={{
                                                    width: '10px',
                                                    minWidth: 'unset',
                                                    // color: 'grey',
                                                    // border: '1px solid #cbc9c9',
                                                  }}
                                                  onClick={() => {
                                                    resendInviteLink(
                                                      participant.uuid,
                                                      participant.participant_id
                                                    );
                                                  }}
                                                >
                                                  <Link style={{ fontSize: '16px' }} />
                                                </Button>
                                              </Tooltip>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </Grid>
                                        {participant?.additional_participants?.map((addPart) => {
                                          return (
                                            <Grid container className='mt-8'>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                className='d-flex align-items-center'
                                              >
                                                <div className='f-13-5 fw-500 mr-4'>
                                                  Additional{' '}
                                                  {participant?.participant_type_name
                                                    ? participant?.participant_type_name
                                                    : 'Participant'}{' '}
                                                  ({addPart?.first_name + ' ' + addPart?.last_name}
                                                  ):
                                                </div>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={linkStatus[participant?.uuid] ? 10 : 12}
                                                sm={linkStatus[participant?.uuid] ? 10 : 12}
                                              >
                                                <div
                                                  className='d-flex'
                                                  style={{ flexDirection: 'row' }}
                                                >
                                                  {' '}
                                                  <div
                                                    className='mt-4 f-13-5 fw-500 cursor-pointer'
                                                    style={{
                                                      pointerEvents: !linkStatus[participant?.uuid]
                                                        ? 'none'
                                                        : 'unset',
                                                      color: !linkStatus[participant?.uuid]
                                                        ? 'grey'
                                                        : '#005fd4',
                                                    }}
                                                    onClick={(e) => {
                                                      orderDetail?.domain
                                                        ? window.open(
                                                            `${orderDetail?.domain}/questions/invite/${participant?.uuid}/${addPart?.participant_number}`,
                                                            '_blank'
                                                          )
                                                        : window.open(
                                                            `https://${domainName}/questions/invite/${participant?.uuid}/${addPart?.participant_number}`,
                                                            '_blank'
                                                          );
                                                    }}
                                                  >
                                                    {`${
                                                      orderDetail?.domain
                                                        ? orderDetail?.domain
                                                        : domainName
                                                    }/questions/invite/${participant?.uuid}/${
                                                      addPart?.participant_number
                                                    }`}
                                                  </div>
                                                  {!linkStatus[participant?.uuid] ? (
                                                    <div>
                                                      <Tooltip title='Click to get latest status of invite link.'>
                                                        <Button
                                                          variant='outlined'
                                                          style={{
                                                            minWidth: '0px',
                                                            color: 'orange',
                                                            border: 'none',
                                                          }}
                                                          onClick={() => {
                                                            getLinkStatus(
                                                              orderDetail?.participants
                                                            );
                                                          }}
                                                        >
                                                          <Cached fontSize='small' />
                                                        </Button>
                                                      </Tooltip>
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </Grid>
                                              <Grid item xs={2} sm={2}>
                                                {' '}
                                                {linkStatus[participant?.uuid] ? (
                                                  <div className='ml-12'>
                                                    <Tooltip title='Click to copy participant invite link.'>
                                                      <Button
                                                        variant='outlined'
                                                        color='secondary'
                                                        style={{
                                                          width: '10px',
                                                          minWidth: 'unset',
                                                          // color: 'grey',
                                                          // border: '1px solid #cbc9c9',
                                                        }}
                                                        className='mr-8'
                                                        onClick={() => {
                                                          copy(
                                                            `${
                                                              orderDetail?.domain
                                                                ? orderDetail?.domain
                                                                : domainName
                                                            }/questions/invite/${
                                                              participant?.uuid
                                                            }/${addPart?.participant_number}`
                                                          );
                                                          toast.success('Copied to clipboard!');
                                                        }}
                                                      >
                                                        <ContentCopyIcon
                                                          style={{ fontSize: '16px' }}
                                                        />
                                                      </Button>
                                                    </Tooltip>
                                                    <Tooltip title='Click to resend participant invite link.'>
                                                      <Button
                                                        variant='outlined'
                                                        style={{
                                                          width: '10px',
                                                          minWidth: 'unset',
                                                          // color: 'grey',
                                                          // border: '1px solid #cbc9c9',
                                                        }}
                                                        onClick={() => {
                                                          resendInviteLink(
                                                            participant.uuid,
                                                            addPart.id
                                                          );
                                                        }}
                                                      >
                                                        <Link style={{ fontSize: '16px' }} />
                                                      </Button>
                                                    </Tooltip>
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </Grid>
                                            </Grid>
                                          );
                                        })}
                                      </Grid>

                                      {!linkStatus[participant?.uuid] ? (
                                        // <div className='f-13 ml-16'>
                                        // The link will be active when data lookups are completed and
                                        // compiled please wait for some time. Click on refresh button
                                        // above to fetch latest status of link
                                        // </div>
                                        <Alert
                                          severity='info'
                                          className='f-13 mt-8 fw-450'
                                          style={{ padding: '2px 10px' }}
                                        >
                                          The participant invite link will be active once data
                                          lookups are completed and compiled. Click on the refresh
                                          button above to fetch the latest status of the link.
                                        </Alert>
                                      ) : (
                                        ''
                                      )}
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                  <Grid container className='pl-8'>
                                    {orderDetail?.is_bank_account_client && !participant?.is_new ? (
                                      <>
                                        <Grid item xs={12} sm={12} className='mt-8 mb-8'>
                                          <div className='color-p f-14 fw-550'>
                                            Bank Account Details
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <label>Bank Account#</label>
                                          <div className='f-12 color-g mt-4'>
                                            {participant?.account_number ?? '-'}
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <label>Bank ABA</label>
                                          <div className='f-12 color-g mt-4'>
                                            {participant?.routing_number ?? '-'}
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <label>Name On Account</label>
                                          <div className='f-12 color-g mt-4'>
                                            {participant?.name_on_account ?? '-'}
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <label>Verification Status</label>
                                          <div className='pl-2'>
                                            {participant?.bank_account_status === 'verified' ? (
                                              <div className='f-12 fw-550 color-s mt-4 d-flex align-items-center'>
                                                Verified{' '}
                                                <VerifiedUser className='f-16 ml-4 color-s' />
                                              </div>
                                            ) : (
                                              <div className='f-12 fw-550 error mt-4 d-flex align-items-center'>
                                                Not-Verified
                                                <ErrorOutline className='f-16 ml-4 error' />
                                              </div>
                                            )}
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                          <label>Bank Name</label>
                                          <div className='f-12 color-g mt-4'>
                                            {participant?.lyons_response?.primaryInstitution
                                              ?.Name ?? '-'}
                                          </div>
                                        </Grid>
                                        {participant?.status_desc &&
                                        participant?.bank_account_status !== 'verified' ? (
                                          <Grid item xs={12} sm={4}>
                                            <label>Error Details</label>
                                            <div className='f-12 error mt-4'>
                                              {participant?.status_desc ?? '-'}
                                            </div>
                                          </Grid>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={12} className={`d-flex justify-between`}>
                                    {!participant?.is_new ? (
                                      <div>
                                        <Button
                                          style={{ height: '35px' }}
                                          className='add-participant-btn mt-16'
                                          variant='outlined'
                                          color='primary'
                                          onClick={() =>
                                            navigate(
                                              `/${
                                                isClientUser
                                                  ? isImport
                                                    ? 'orders/imported-orders'
                                                    : 'orders'
                                                  : isImport
                                                  ? 'admin/imported-orders'
                                                  : 'admin/orders'
                                              }/${id}/${participant.order_part_participant_id}`
                                            )
                                          }
                                        >
                                          Form Details & Documents{' '}
                                          <KeyboardTabRounded
                                            className='mr-4 ml-4'
                                            style={{ fontSize: '20px' }}
                                          />{' '}
                                          <span className='fw-900'>...</span>
                                        </Button>
                                        <Tooltip
                                          title={
                                            isDisable
                                              ? 'You cannot update order details as the order has already been completed.'
                                              : null
                                          }
                                        ></Tooltip>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {!participant?.is_new && !isAdmin ? (
                                      <Tooltip
                                        title={
                                          participant?.data_verify_status === 'completed'
                                            ? 'You cannot update participant details as the participant form has already been verified.'
                                            : null
                                        }
                                      >
                                        <div>
                                          <Button
                                            type='button'
                                            onClick={() => {
                                              updateParticipantData(
                                                participant?.participant_id,
                                                index
                                              );
                                            }}
                                            className='add-participant-btn mt-16 ml-8'
                                            variant='contained'
                                            color='primary'
                                            disabled={
                                              participant?.data_verify_status === 'completed'
                                            }
                                            style={{ height: '35px' }}
                                          >
                                            Update
                                          </Button>
                                        </div>
                                      </Tooltip>
                                    ) : (
                                      ''
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                    <div className='mt-16 d-flex flex-end-imp'>
                      <Tooltip></Tooltip>
                      {!isAdmin && newParticipants?.filter((newP) => newP?.is_new)?.length ? (
                        <Button
                          type='button'
                          onClick={onParticipantsChange}
                          className='add-participant-btn'
                          variant='contained'
                          color='primary'
                          style={{ height: '35px' }}
                        >
                          Add Participants
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/* <Typography variant='h4' className='mb-0'></Typography>
              <Tabs value={tabValue} onChange={handleChangeTab} className='order-detail-tab'>
                <Tab
                  label='ORDER INFORMATION'
                  icon={<ShoppingCart fontSize='small' />}
                  iconPosition='start'
                />
                <Tab label='Participants' icon={<Groups fontSize='small' />} iconPosition='start' />
              </Tabs> */}
            </CardContent>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderDetail;
